import { FC, useContext, useEffect } from 'react';
import { Typography, theme, Space } from 'antd';
import { HolderOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import {
    UIElementType,
    UIField,
    UIEntityReference,
    fieldType,
    UIMetaField,
    EditorUIElement,
    EditorUIContainer
} from '../../types/System.types';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import { LayoutEditorControls } from './LayoutEditorControls';
import { useLayoutEditorStyles } from './element/LayoutEditorElement.styles';

const { Text } = Typography;
const { useToken } = theme;

/**
 * Props for the LayoutEditorElement component
 */
interface LayoutEditorElementProps {
    /** The UI element to render */
    element: EditorUIElement;
    /** The parent element containing this element, null if root */
    parentElement: EditorUIElement | null;
    /** Whether this element is currently selected */
    isSelected?: boolean;
    /** Callback when this element is selected */
    onSelect?: () => void;
    /** Additional CSS styles to apply */
    style?: React.CSSProperties;
}

/**
 * A draggable and configurable UI element in the layout editor.
 * Supports containers (Grid, Row, Column) and leaf elements (Field, MetaField, EntityReference).
 * Handles drag and drop, selection, and keyboard interactions.
 * @component
 */
export const LayoutEditorElement: FC<LayoutEditorElementProps> = ({
    element,
    parentElement,
    isSelected,
    onSelect,
    style,
}) => {
    const { token } = useToken();
    const editor = useContext(LayoutEditorContext);
    if (!editor) throw new Error('Editor must be used within LayoutEditorProvider');

    const { removeElement, selectElement } = editor;
    const isContainer = [UIElementType.Grid, UIElementType.Row, UIElementType.Column].includes(element.uiElementType);
    const isColumn = element.uiElementType === UIElementType.Column;
    const isField = element.uiElementType === UIElementType.Field;
    const isMeta = element.uiElementType === UIElementType.MetaField;

    const { getElementStyle, getTypeTextStyle, getMetaIconStyle, getDragHandleStyle } = useLayoutEditorStyles();

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (!isSelected) return;

            if (['Delete', 'Backspace', 'Enter', ' '].includes(e.key)) {
                e.preventDefault();
            }

            switch (e.key) {
                case 'Escape':
                    selectElement(null);
                    break;
                case 'Delete':
                case 'Backspace':
                    if (parentElement) {
                        removeElement(parentElement as EditorUIContainer, element.dndId);
                    }
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [editor, isSelected, parentElement, element.dndId, removeElement, selectElement]);

    const currentIndex = parentElement ? parentElement.children?.indexOf(element) : -1;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        isOver,
    } = useSortable({
        id: element.dndId,
        data: {
            type: element.uiElementType,
            elementId: element.dndId,
            container: parentElement?.dndId || 'e0',
            index: currentIndex,
            isContainer
        },
        disabled: parentElement === null
    });

    const type = isField 
        ? fieldType[(element as UIField).fieldTypeId]?.fieldDataTypeId 
        : isMeta
            ? 'meta'
            : 'entity';

    const elementStyle = getElementStyle({
        isContainer,
        isColumn,
        isDragging,
        isSelected: !!isSelected,
        isMeta,
        isField,
        isOver,
        style,
        elementProps: element.props,
    });

    const renderChildren = () => {
        if (isContainer) {
            const container = element as EditorUIElement;
            const childIds = container.children?.map((child: EditorUIElement) => child.dndId) || [];

            return (
                <SortableContext 
                    items={childIds} 
                    strategy={rectSortingStrategy}
                >
                    {container.children?.map((child) => (
                        <LayoutEditorElement
                            key={child.dndId}
                            element={child}
                            parentElement={container}
                            isSelected={editor.selectedElement === child}
                            onSelect={() => selectElement(child)}
                        />
                    ))}
                </SortableContext>
            );
        }
    };

    const renderLabel = () => {
        if (element.uiElementType === UIElementType.Field) {
            const ft = fieldType[element.fieldTypeId];
            return (
                <Text type="secondary">
                    <Text>{ft?.description?.shortLabel || element.fieldId}</Text>
                    {element.required && ' *'}
                </Text>
            );
        } else if (element.uiElementType === UIElementType.EntityReference) {
            return (
                <Text type="secondary">
                    <Text>{element.entityId}</Text>
                    {element.required && ' *'}
                </Text>
            );
        }
        return null;
    };

    return (
        <div
            ref={setNodeRef}
            id={element.dndId}
            style={{
                ...elementStyle,
                transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
                transition: transition,
            }}
            onClick={(e) => {
                e.stopPropagation();
                onSelect?.();
            }}
            {...attributes}
            {...listeners}
        >

            {isContainer && (
                <Text type="secondary" style={getTypeTextStyle()}>
                    <small>{element.uiElementType.toUpperCase()}</small>
                </Text>
            )}
            {isSelected && <LayoutEditorControls element={element} parentElement={parentElement} />}
            {!isContainer && (
                <Text>
                    {isMeta ? (
                        <>
                            {(element as UIMetaField).metaFieldId}
                            <InfoCircleOutlined style={getMetaIconStyle()} />
                        </>
                    ) : (
                        <>
                            {renderLabel()}
                            &nbsp;
                            <Text type="secondary" style={{ fontSize: token.fontSizeSM }}>
                                {type}
                            </Text>
                        </>
                    )}
                </Text>
            )}
            {renderChildren()}
            <HolderOutlined style={getDragHandleStyle(isContainer, isMeta, isField)} />
        </div>
    );
}; 