import React, { FC, useContext, RefObject } from 'react';
import { Typography } from 'antd';
import LayoutUI from './LayoutUI';
import UnmappedFieldsUI from './UnmappedFieldsUI';
import { FormContext } from '../../providers/artifacts/FormProvider';
import FormUITitle from './FormUITitle';

const { Text } = Typography;

interface FormUIProps {
  contentRef: RefObject<HTMLDivElement>;
}

const FormUI: FC<FormUIProps> = React.memo(({ contentRef }) => {
  const {
    formTypeOfSelectedForm,
  } = useContext(FormContext);

  const { defaultUILayout } = formTypeOfSelectedForm?.data || {};

  if (!defaultUILayout?.structure) {
    return <Text type='danger'>Error: No UI layout defined for this form.</Text>;
  }

  return (
    <div
      ref={contentRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        gap: '2px',
        paddingBottom: 25,
      }}
    >
      <FormUITitle />
      <LayoutUI
        uiElement={defaultUILayout.structure}
      />
      <UnmappedFieldsUI
        uiElement={defaultUILayout.structure}
      />
    </div>
  );
});

FormUI.displayName = 'FormUI';

export default FormUI;