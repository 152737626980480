import { FC, useContext } from 'react';
import { Typography } from 'antd';
import { FormContext } from '../../providers/artifacts/FormProvider';
import { FormVersionContext } from '../../providers/artifacts/FormVersionProvider';

const { Title } = Typography;

/** Editable form title component */
const FormUITitle: FC = () => {
  const { selectedForm } = useContext(FormContext);
  const { formVersionAccess } = useContext(FormVersionContext);

  const handleTitleChange = (newTitle: string) => {
    if (selectedForm.data && formVersionAccess?.canWrite && newTitle !== selectedForm.data.title) {
      selectedForm.debouncedSet({ title: newTitle });
    }
  };

  return (
    <Title
      level={3}
      style={{
        width: '100%',
        marginTop: 0,
        marginBottom: 5,
      }}
      editable={formVersionAccess?.canWrite ? { onChange: handleTitleChange } : false}
    >
      {selectedForm.data?.title || 'Untitled Form'}
    </Title>
  );
};

export default FormUITitle; 