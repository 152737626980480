/**
 * EntityContext provides entity-related data and actions to components.
 * It manages entities, entity records, selection, and CRUD operations.
 */
import React, { createContext, useState, useCallback, useMemo, FC, useContext, useEffect } from 'react';
import { useBoundCollection } from '../../use/data/useBoundCollection';
import { useBoundDoc } from '../../use/data/useBoundDoc';
import { useAdd } from '../../use/data/useAdd';
import { useRemove } from '../../use/data/useRemove';
import { EntityRecord, DocId } from '../../types/System.types';
import { serverTimestamp } from 'firebase/firestore';
import { UserContext } from '../UserProvider';
import { EntityContext } from './EntityProvider';

/** Context for managing entity record-related state and operations */
export interface EntityRecordContextValue {
    /** Records belonging to selected entity */
    entityRecords: EntityRecord[];
    /** Currently selected record */
    selectedEntityRecord: EntityRecord | null;
    /** Select a record by ID */
    selectEntityRecord: (recordId: string | undefined) => Promise<void>;
    /** Update selected record */
    setRecord: (updates: Partial<EntityRecord>) => void;
    /** Delete selected record */
    deleteRecord: (recordId: DocId) => Promise<void>;
    /** Debounced record update */
    debouncedSetRecord: (updates: Partial<EntityRecord>) => void;
    /** Create new entity record */
    createNewRecord: () => Promise<DocId>;
    /** Clear selected entity record */
    clearEntityRecord: () => void;
}

export const EntityRecordContext = createContext<EntityRecordContextValue>({} as EntityRecordContextValue);

/** Props for EntityRecordProvider component */
interface EntityRecordProviderProps {
    /** Initial record ID to select */
    initialRecordId?: DocId;
    /** Child components */
    children: React.ReactNode;
}

/**
 * Provider component for entity record-related state and operations
 * @example
 * <EntityRecordProvider initialEntityId="entity123">
 *   <YourComponent />
 * </EntityRecordProvider>
 */
export const EntityRecordProvider: FC<EntityRecordProviderProps> = ({ children, initialRecordId }) => {
    const [selectedEntityRecordId, setSelectedEntityRecordId] = useState<DocId | undefined>(initialRecordId);
    const {
        selectedEntity,
    } = useContext(EntityContext);
    const selectedEntityId = selectedEntity?.docId;

    const {
        user
    } = useContext(UserContext);

    const {
        data: entityRecords = [],
    } = useBoundCollection<EntityRecord>({
        path: selectedEntityId ? `entities/${selectedEntityId}/records` : '',
        enabled: !!selectedEntityId,
        initialOrderBy: [{ field: 'name', direction: 'asc' }],
        initialLimit: 5000,
    });

    const {
        data: selectedEntityRecord,
        updateData: setRecord,
        debouncedSet: debouncedSetRecord,
        clear: clearRecord,
    } = useBoundDoc<EntityRecord>({
        path: selectedEntityId ? `entities/${selectedEntityId}/records` : '',
        docId: selectedEntityRecordId,
        enabled: !!selectedEntityId && !!selectedEntityRecordId,
    });

    const { add } = useAdd();
    const { remove } = useRemove();

    const clearEntityRecord = useCallback(() => {
        clearRecord();
        setSelectedEntityRecordId(undefined);
    }, [clearRecord]);

    const selectEntityRecord = useCallback(async (recordId: string | undefined) => {
        clearRecord();
        setSelectedEntityRecordId(recordId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialRecordId) {
            selectEntityRecord(initialRecordId);
        }
    }, [initialRecordId, selectEntityRecord]);

    const createNewRecord = useCallback(async () => {
        if (!selectedEntityId) throw new Error('No entity selected');
        const newRecord = {
            name: 'New Record',
            fields: {},
            meta: {
                user: user?.uid,
                created: serverTimestamp(),
                modified: serverTimestamp(),
                status: 'active'
            }
        };
        const newRecordId = await add(`entities/${selectedEntityId}/records`, newRecord);
        selectEntityRecord(newRecordId);
        return newRecordId;
    }, [selectedEntityId, add, selectEntityRecord, user?.uid]);

    const deleteRecord = useCallback(async (recordId: DocId) => {
        if (!selectedEntityId) throw new Error('No entity selected');
        await remove(`entities/${selectedEntityId}/records`, recordId);
        if (selectedEntityRecordId === recordId) {
            clearRecord();
            setSelectedEntityRecordId(undefined);
        }
    }, [selectedEntityId, selectedEntityRecordId, remove, clearRecord]);

    const contextValue = useMemo(
        () => ({
            entityRecords,
            selectedEntityRecord: selectedEntityRecord || null,
            selectEntityRecord,
            setRecord,
            deleteRecord,
            debouncedSetRecord,
            createNewRecord,
            clearEntityRecord,
        }),
        [
            entityRecords,
            selectedEntityRecord,
            selectEntityRecord,
            setRecord,
            deleteRecord,
            debouncedSetRecord,
            createNewRecord,
            clearEntityRecord,
        ]
    );

    return <EntityRecordContext.Provider value={contextValue}>
        {children}
    </EntityRecordContext.Provider>;
};