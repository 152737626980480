import { useCallback, useContext, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { 
    Entity, 
    TimeType, 
    AgentType, 
    UILayout, 
    UIElementType, 
    Description,
} from '../../types/System.types';
import { RecordStatus } from '../../types/System.Parameters.types';
import { useLayoutEditorBase } from './useLayoutEditorBase';
import { useBoundCollection } from '../data/useBoundCollection';
import { useGet } from '../data/useGet';
import { useAdd } from '../data/useAdd';
import { useRemove } from '../data/useRemove';
import { useSet } from '../data/useSet';
import { UserContext } from '../../providers/UserProvider';

/**
 * Custom hook for managing entity layout editing functionality.
 * Provides state management and operations for entity layouts including:
 * - Entity selection and creation
 * - Layout modifications
 * - Description updates
 * - Save operations
 * - Entity deletion
 * 
 * @returns {Object} Entity editor state and methods
 * @property {Entity | null} structure - Currently selected entity structure
 * @property {Entity[]} structures - List of available entities
 * @property {string | undefined} selectedStructureId - ID of the currently selected structure
 * @property {Entity[]} entities - Alias for structures, list of available entities
 */
export const useLayoutEditorEntity = () => {
    const { user } = useContext(UserContext);
    const [selectedStructureId, setSelectedStructureId] = useState<string | undefined>();
    const [structure, setStructure] = useState<Entity | null>(null);
    const { get } = useGet();
    const { add } = useAdd();
    const { remove } = useRemove();
    const { set } = useSet();
    
    const { data: structures = [] } = useBoundCollection<Entity>({
        path: 'entities',
        enabled: true
    });

    /**
     * Saves the current structure (Entity) layout and description
     * @param {UILayout} layout - The UI layout to save
     * @param {string} userId - ID of the user making the change
     * @param {Description} description - Updated description for the Entity
     * @throws {Error} If no structure is selected or save fails
     */
    const handleSave = useCallback(async (layout: UILayout, description: Description) => {
        if (!structure || !selectedStructureId) {
            console.error('Save failed: No structure selected');
            throw new Error('No structure selected');
        }
        
        try {
            const updates: Omit<Entity, 'docId'> = {
                defaultUILayout: layout,
                description,
                meta: {
                    lastModified: Timestamp.now(),
                    userId: user?.uid,
                    agentType: AgentType.User
                },
                status: structure.status
            };

            await set('entities', selectedStructureId, updates);
        } catch (error) {
            console.error('Error saving entity:', error);
            throw error;
        }
    }, [structure, selectedStructureId, set]);

    const baseEditor = useLayoutEditorBase({
        onSave: handleSave
    });

    const { 
        dispatch, 
    } = baseEditor;

    /**
     * Updates a specific field in the structure description
     * @param {keyof Description} key - The description field to update
     * @param {string} value - New value for the field
     */
    const handleDescriptionChange = (key: keyof Description, value: string) => {
        dispatch({
            type: 'UPDATE_DESCRIPTION',
            payload: {
                [key]: value
            }
        });
    };

    /**
     * Selects a structure (Entity) for editing and loads its layout
     * @param {string} structureId - ID of the Entity to select
     * @returns {Promise<boolean>} Promise that resolves with true if selection was successful
     * @throws {Error} If structure cannot be loaded
     */
    const selectStructure = useCallback(async (structureId: string) => {
        try {
            const fetchedEntity = await get<Entity>('entities', structureId);
            if (!fetchedEntity) {
                console.error('Entity not found:', structureId);
                return false;
            }
            
            setSelectedStructureId(structureId);
            setStructure(fetchedEntity);
            dispatch({
                type: 'SELECT_STRUCTURE',
                payload: {
                    layout: fetchedEntity.defaultUILayout,
                    description: fetchedEntity.description
                }
            });
            return true;
        } catch (error) {
            console.error('Failed to select entity:', error);
            throw error;
        }
    }, [get, dispatch]);

    /**
     * Creates a new structure (Entity) with default layout and description
     * @returns {Promise<string>} Promise that resolves with the ID of the newly created Entity
     * @throws {Error} If user is not logged in or creation fails
     */
    const createStructure = useCallback(async () => {
        if (!user) throw new Error('User must be logged in');

        try {
            const now = Timestamp.now() as TimeType;
            const newEntity: Omit<Entity, 'docId'> = {
                defaultUILayout: {
                    structure: {
                        uiElementType: UIElementType.Column,
                        children: []
                    }
                },
                description: {
                    shortLabel: 'New Record Type',
                    shortDescription: '',
                    longLabel: '',
                    longDescription: 'Recently created Record Type, please update this description.'
                },
                meta: {
                    created: now,
                    lastModified: now,
                    userId: user.uid,
                    agentType: AgentType.User
                },
                status: RecordStatus.Active
            };

            const newEntityId = await add('entities', newEntity);
            setSelectedStructureId(newEntityId);
            setStructure({ 
                ...newEntity, 
                docId: newEntityId 
            });
            dispatch({
                type: 'SELECT_STRUCTURE',
                payload: {
                    layout: newEntity.defaultUILayout,
                    description: newEntity.description
                }
            });
            return newEntityId;
        } catch (error) {
            console.error('Error creating entity:', error);
            throw error;
        }
    }, [user, add, dispatch]);

    /**
     * Deletes a structure (Entity) and clears selection
     * @param {string} structureId - ID of the Entity to delete
     * @returns {Promise<boolean>} Promise that resolves with true if deletion was successful
     */
    const deleteStructure = useCallback(async (structureId: string) => {
        try {
            await remove('entities', structureId);
            setSelectedStructureId(undefined);
            setStructure(null);
            dispatch({ type: 'RESET_LAYOUT' });
            return true;
        } catch (error) {
            console.error('Error deleting entity:', error);
            return false;
        }
    }, [remove, dispatch]);

    /**
     * Clears the current structure selection and resets layout
     */
    const clearSelection = () => {
        setSelectedStructureId(undefined);
        setStructure(null);
        dispatch({ type: 'RESET_LAYOUT' });
    };

    return {
        ...baseEditor,
        structure,
        structures,
        entities: structures,
        createStructure,
        deleteStructure,
        selectStructure,
        selectedStructureId,
        clearSelection,
        handleDescriptionChange,
    };
}; 