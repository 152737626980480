import { FC } from 'react';
import Navb from '../components/Navb';
import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import { FormProvider } from '../providers/artifacts/FormProvider';
import { FormVersionProvider } from '../providers/artifacts/FormVersionProvider';
import { FormPageContent } from './FormPageContent';

const FormPage: FC = () => {
	return (
		<FormProvider>
			<FormVersionProvider>
				<FlexPage>
					<Navb />
					<FlexContent>
						<FormPageContent />
					</FlexContent>
				</FlexPage>
			</FormVersionProvider>
		</FormProvider>
	);
};

export default FormPage;