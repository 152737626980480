import React, { useState, useEffect, useContext } from 'react';
import {
    Upload,
    Button,
    message,
    Modal,
    Divider,
    Typography,
    Progress,
    theme
} from 'antd';
import {
    UploadOutlined,
    DeleteOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import type { UploadFile, UploadProps, UploadFileStatus, RcFile } from 'antd/es/upload/interface';
import { ref, uploadBytes, getDownloadURL, deleteObject, getBlob } from 'firebase/storage';
import { storage } from '../../services/startStorage';
import { addDoc, collection, getDoc, deleteDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { firestore } from '../../services/startFirestore';
import FlexCard from './FlexCard';
import FlexBox from './FlexBox';
import { Attachment } from '../../types/System.types';
import { FormContext } from '../../providers/artifacts/FormProvider';
import { EntityContext } from '../../providers/artifacts/EntityProvider';
import { EntityRecordContext } from '../../providers/artifacts/EntityRecordProvider';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW } from '../../types/System.Parameters.types';

const { Text } = Typography;

const { useToken } = theme;

interface AttachmentUIProps {
    value?: string[];
    onChange?: (value: string[]) => void;
    disabled?: boolean;
    folder?: string;
    maxCount?: number;
}

export const AttachmentUI: React.FC<AttachmentUIProps> = ({
    value = [],
    onChange,
    disabled = false,
    folder = 'attachments',
}) => {
    const { token } = useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const [fileList, setFileList] = useState<UploadFile[]>(() =>
        value.map((url, index) => ({
            uid: `-${index}`,
            name: url.split('/').pop()?.split('?')[0].split('%2F').pop() || '',
            status: 'done',
            url: url,
            type: 'file'
        }))
    );
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false);
    const [pendingUpload, setPendingUpload] = useState<{
        files: RcFile[];
        existingFiles: {
            newFile: RcFile;
            existingFile: UploadFile;
        }[];
        onSuccess?: (response: any) => void;
        onError?: (error: Error) => void;
    } | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [pendingDelete, setPendingDelete] = useState<{
        fileUid: string;
        fileName: string;
    } | null>(null);
    const [batchFiles, setBatchFiles] = useState<RcFile[]>([]);

    const formContext = useContext(FormContext);
    const entityContext = useContext(EntityContext);
    const entityRecordContext = useContext(EntityRecordContext);

    const performUpload = async (
        files: File[],
        onSuccess?: (response: any) => void,
        onError?: (error: Error) => void
    ) => {
        try {
            setUploading(true);
            setUploadProgress(0);
            const totalFiles = files.length;
            let completedFiles = 0;

            // Process all files in parallel
            const uploadPromises = files.map(async (file) => {
                const attachmentData: Omit<Attachment, 'docId' | 'storageRef'> = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    storageUrl: '',
                    storagePath: '',
                    formPath: entityContext.insideEntityContext ? ''
                        : `forms/${formContext.selectedForm?.data?.docId}`,
                    entityRecordPath: entityContext.insideEntityContext
                        ? `entities/${entityContext.selectedEntity?.docId}/records/${entityRecordContext.selectedEntityRecord?.docId}`
                        : '',
                    meta: {
                        created: Timestamp.now(),
                        lastModified: Timestamp.now(),
                    }
                };

                const attachmentDoc = await addDoc(collection(firestore, 'attachments'), attachmentData);

                const storageRef = ref(storage, `${folder}/${attachmentDoc.id}_${file.name}`);
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);

                await updateDoc(attachmentDoc, {
                    storageUrl: downloadURL,
                    storagePath: storageRef.fullPath
                });

                completedFiles++;
                setUploadProgress((completedFiles / totalFiles) * 100);

                return {
                    id: attachmentDoc.id,
                    name: file.name,
                    type: file.type
                };
            });

            // Wait for all uploads to complete
            const results = await Promise.all(uploadPromises);

            // Collect all attachment IDs
            const newAttachmentIds = results.map(result => result.id);

            // Update state with all new attachments at once
            const newUrls = [...value, ...newAttachmentIds];

            if (newUrls.length > 0) {
                onChange?.(newUrls);
            }

            // Update file list with all new files at once
            setFileList(prev => [
                ...prev,
                ...results.map(({ id, name, type }) => ({
                    uid: id,
                    name: name,
                    status: 'done' as UploadFileStatus,
                    url: id,
                    type: type
                } as UploadFile))
            ]);

            onSuccess?.(newAttachmentIds);
            messageApi.success(`${totalFiles} file${totalFiles > 1 ? 's' : ''} uploaded successfully`);
        } catch (error) {
            onError?.(new Error('Upload failed'));
            messageApi.error('Upload failed');
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    const checkExistingFiles = (filesToCheck: RcFile[]) => {
        const existingFiles = filesToCheck
            .map(file => {
                const existing = fileList.find(f => f.name === file.name);
                return existing ? { newFile: file, existingFile: existing } : null;
            })
            .filter((item): item is { newFile: RcFile; existingFile: UploadFile } => item !== null);

        return existingFiles;
    };

    const handleBatchUpload = async (files: RcFile[]) => {
        try {
            // Check for existing files
            const existingFiles = checkExistingFiles(files);

            if (existingFiles.length > 0) {
                setPendingUpload({
                    files,
                    existingFiles,
                    onSuccess: () => messageApi.success(`${files.length} file${files.length > 1 ? 's' : ''} uploaded successfully`),
                    onError: (error) => messageApi.error(error.message)
                });
                setIsOverwriteModalOpen(true);
                return;
            }

            await performUpload(
                files,
                () => messageApi.success(`${files.length} file${files.length > 1 ? 's' : ''} uploaded successfully`),
                (error) => messageApi.error(error.message)
            );
        } catch (error) {
            messageApi.error('Failed to process upload');
        }
    };

    // Effect to handle batch uploads
    useEffect(() => {
        if (batchFiles.length > 0) {
            handleBatchUpload(batchFiles);
            setBatchFiles([]); // Clear the batch
        }
    }, [batchFiles]);

    const handleOverwriteConfirm = async () => {
        setIsOverwriteModalOpen(false);
        if (!pendingUpload) return;

        const { files, existingFiles, onSuccess, onError } = pendingUpload;

        try {
            setUploading(true);

            // 1. Collect IDs of files to be removed
            const idsToRemove = existingFiles.map(item => item.existingFile.uid);

            // 2. Delete existing files from storage and Firestore
            for (const { existingFile } of existingFiles) {
                const existingStorageRef = ref(storage, `${folder}/${existingFile.uid}_${existingFile.name}`);
                await deleteObject(existingStorageRef);

                const attachmentRef = doc(firestore, 'attachments', existingFile.uid);
                await deleteDoc(attachmentRef);
            }

            // 3. Upload new files
            const uploadResults = await Promise.all(
                files.map(async (file) => {
                    const attachmentData: Omit<Attachment, 'docId' | 'storageRef'> = {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        storageUrl: '',
                        storagePath: '',
                        formPath: entityContext.insideEntityContext ? ''
                            : `forms/${formContext.selectedForm?.data?.docId}`,
                        entityRecordPath: entityContext.insideEntityContext
                            ? `entities/${entityContext.selectedEntity?.docId}/records/${entityRecordContext.selectedEntityRecord?.docId}`
                            : '',
                        meta: {
                            created: Timestamp.now(),
                            lastModified: Timestamp.now(),
                        }
                    };

                    const attachmentDoc = await addDoc(collection(firestore, 'attachments'), attachmentData);
                    const storageRef = ref(storage, `${folder}/${attachmentDoc.id}_${file.name}`);
                    await uploadBytes(storageRef, file);
                    const downloadURL = await getDownloadURL(storageRef);

                    await updateDoc(attachmentDoc, {
                        storageUrl: downloadURL,
                        storagePath: storageRef.fullPath
                    });

                    return {
                        id: attachmentDoc.id,
                        name: file.name,
                        type: file.type
                    };
                })
            );

            // 4. Update state all at once
            const newAttachmentIds = uploadResults.map(result => result.id);

            // Filter out old IDs and add new ones
            const finalIds = value
                .filter(id => !idsToRemove.includes(id))
                .concat(newAttachmentIds);

            // Update parent component once with final state
            onChange?.(finalIds);

            // Update file list state
            setFileList(prev => [
                ...prev.filter(f => !idsToRemove.includes(f.uid)),
                ...uploadResults.map(({ id, name, type }) => ({
                    uid: id,
                    name: name,
                    status: 'done' as UploadFileStatus,
                    url: id,
                    type: type
                } as UploadFile))
            ]);

            onSuccess?.(newAttachmentIds);
            messageApi.success(`${files.length} file${files.length > 1 ? 's' : ''} uploaded successfully`);
        } catch (error) {
            console.error('Error handling overwrite:', error);
            onError?.(new Error('Failed to overwrite files'));
            messageApi.error('Failed to overwrite files');
        } finally {
            setUploading(false);
            setPendingUpload(null);
        }
    };

    const handleOverwriteCancel = () => {
        setIsOverwriteModalOpen(false);
        pendingUpload?.onError?.(new Error('Upload cancelled'));
        setPendingUpload(null);
    };

    const handleDeleteConfirm = async () => {
        if (!pendingDelete) return;

        try {
            const { fileUid } = pendingDelete;
            const attachmentRef = doc(firestore, 'attachments', fileUid);
            const attachmentDoc = await getDoc(attachmentRef);

            if (!attachmentDoc.exists()) {
                throw new Error('Attachment document not found');
            }

            const attachmentData = attachmentDoc.data();

            if (attachmentData.storagePath) {
                const fileRef = ref(storage, attachmentData.storagePath);
                await deleteObject(fileRef);
            } else {
                console.warn('No storage path found for attachment:', fileUid);
            }

            await deleteDoc(attachmentRef);

            const newUids = value.filter(uid => uid !== fileUid);
            onChange?.(newUids);
            setFileList(prevList => prevList.filter(file => file.uid !== fileUid));

            messageApi.success('File deleted successfully');
        } catch (error) {
            console.error('Delete error:', error);
            messageApi.error('Delete failed');
        } finally {
            setIsDeleteModalOpen(false);
            setPendingDelete(null);
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
        setPendingDelete(null);
    };

    const handleDelete = async (fileUid: string, fileName: string) => {
        setPendingDelete({ fileUid, fileName });
        setIsDeleteModalOpen(true);
    };

    const handleDownload = async (fileUid: string, fileName: string) => {
        try {
            const attachmentRef = doc(firestore, 'attachments', fileUid);
            const attachmentDoc = await getDoc(attachmentRef);

            if (!attachmentDoc.exists()) {
                throw new Error('Attachment document not found');
            }

            const fileRef = ref(storage, attachmentDoc.data().storagePath);
            const blob = await getBlob(fileRef);
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            messageApi.success('File downloaded successfully');
        } catch (error) {
            console.error('Download error:', error);
            messageApi.error('Download failed');
        }
    };

    useEffect(() => {
        const loadAttachments = async () => {
            try {
                const attachments = await Promise.all(
                    value.map(async (attachmentId) => {
                        try {
                            const attachmentRef = doc(firestore, 'attachments', attachmentId);
                            const attachmentDoc = await getDoc(attachmentRef);
                            if (attachmentDoc.exists()) {
                                const data = attachmentDoc.data();
                                return {
                                    uid: attachmentId,
                                    name: data.name,
                                    status: 'done' as const,
                                    url: attachmentId,
                                    type: data.type
                                };
                            }
                            return null;
                        } catch (error) {
                            console.error('Error loading attachment:', error);
                            return null;
                        }
                    })
                );

                setFileList(attachments.filter(Boolean) as UploadFile[]);
            } catch (error) {
                console.error('Error loading attachments:', error);
                messageApi.error('Failed to load attachments');
            }
        };

        if (value.length > 0) {
            loadAttachments();
        } else {
            setFileList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadProps: UploadProps = {
        customRequest: () => { }, // Not used anymore
        fileList,
        multiple: true,
        disabled: disabled || uploading,
        listType: 'text' as const,
        onRemove: () => false,
        directory: false,
        beforeUpload: (file: RcFile, fileList: RcFile[]) => {
            // If this is the last file in the list, trigger the batch upload
            if (file === fileList[fileList.length - 1]) {
                setBatchFiles(fileList);
            }
            return false; // Prevent default upload behavior
        },
        progress: {
            strokeColor: {
                '0%': token.colorPrimary,
                '100%': token.colorSuccess,
            },
            strokeWidth: 2,
            format: (percent?: number) => `${parseFloat((percent || 0).toFixed(2))}%`,
            style: {
                width: '100%',
                top: 0,
                padding: '4px 0'
            }
        },
        style: {
            borderRadius: 'token.borderRadiusLG',
            width: '100%',
        },
        showUploadList: false
    };

    return (
        <FlexCard
            bordered={false}
            stretch
            growCard
            gap={0}
            style={{
                width: '100%',
                maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW
            }}
            bodyStyle={{
                padding: 10
            }}
        >
            {contextHolder}
            <Upload.Dragger {...uploadProps}>
                <FlexBox
                    column
                    justifyCenter
                    gap={2}
                    style={{
                        margin: '-10px -5px' //can't figure out how to remove the padding on the upload component
                    }}
                >
                    <UploadOutlined style={{
                        fontSize: 30,
                        color: token.colorTextSecondary
                    }} />
                    <Text>
                        Click or drag file to this area to upload
                    </Text>
                    <Text type="secondary">
                        Support for single or bulk upload.
                    </Text>
                    {uploading && (
                        <Progress
                            percent={uploadProgress}
                            status="active"
                            strokeColor={{
                                '0%': token.colorPrimary,
                                '100%': token.colorSuccess,
                            }}
                        />
                    )}
                </FlexBox>
            </Upload.Dragger>
            {fileList.length > 0 && (
                <FlexBox column gap={0}>
                    <Divider style={{ margin: '12px 0' }} />
                    {fileList.map((file) => (
                        <FlexBox
                            key={file.uid}
                            stretch
                            style={{
                                padding: 5,
                                borderBottom: `1px solid ${token.colorBorderSecondary}`,
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <FlexBox style={{ width: '100%' }}>
                                <Button
                                    icon={<DownloadOutlined />}
                                    type="link"
                                    onClick={() => file.uid && handleDownload(file.uid, file.name)}
                                    disabled={disabled || !file.uid}
                                    size="small"
                                >
                                    {file.name}
                                </Button>
                                <Button
                                    icon={<DeleteOutlined />}
                                    type="link"
                                    onClick={() => file.uid && handleDelete(file.uid, file.name)}
                                    disabled={disabled || !file.uid}
                                    danger
                                    size="small"
                                />
                            </FlexBox>
                        </FlexBox>
                    ))}
                </FlexBox>
            )}

            <Modal
                title="Files Already Exist"
                open={isOverwriteModalOpen}
                onOk={handleOverwriteConfirm}
                onCancel={handleOverwriteCancel}
                okText="Overwrite All"
                cancelText="Cancel Upload"
                confirmLoading={uploading}
            >
                <p>The following files already exist:</p>
                <div style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    margin: '10px 0',
                    padding: 10,
                    backgroundColor: token.colorFillSecondary,
                    borderRadius: token.borderRadiusLG
                }}>
                    {pendingUpload?.existingFiles.map(({ newFile, existingFile }) => (
                        <div
                            key={newFile.name}
                            style={{
                                padding: '5px 0',
                                borderBottom: `1px solid ${token.colorBorderSecondary}`,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                            }}
                        >
                            <Text style={{ flex: 1 }}>{newFile.name}</Text>
                        </div>
                    ))}
                </div>
                <Text type="warning">
                    Would you like to overwrite these files with the new versions? This action cannot be undone.
                </Text>
            </Modal>

            <Modal
                title="Confirm Delete"
                open={isDeleteModalOpen}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete "{pendingDelete?.fileName}"?</p>
                <p>This action cannot be undone.</p>
            </Modal>
        </FlexCard>
    );
}; 