import { FC, useState, useContext } from 'react';
import { Select, Typography, Button, Tooltip, Modal, Collapse, Table, Empty, Divider, List, Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import Icon from '../atoms/Icon';
import FlexBox from '../atoms/FlexBox';
import FlexCol from '../atoms/FlexCol';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import { LayoutEditorDescription } from './LayoutEditorDescription';
import {
    WorkflowEditorUIDescription,
    MAX_LIMITED_WIDTH_VIEWPORT_NARROW,
    formStateDescription,
    FormStateId
} from '../../types/System.Parameters.types';


const { Text } = Typography;

interface StateTableRecord {
    key: string;
    state: FormStateId;
}

interface StateRowData {
    key: string;
    state: FormStateId;
    description: string;
    allowedNextStates: FormStateId[];
}

/**
 * Form details editor component that allows editing form type configuration and state management.
 * Provides UI for managing form states, descriptions, allowed next states, and form type settings.
 * @component
 */
const LayoutEditorFormDetails: FC = () => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showStateSelectionModal, setShowStateSelectionModal] = useState(false);
    const context = useContext(LayoutEditorContext);

    if (!context || context.type !== 'form') {
        return null;
    }

    const {
        structure: formType,
        layoutEditorState,
        handleStateChange,
        handleAllowedFormsChange,
        handleStatesUpdate,
        canRemoveState,
        structures: formTypes,
        deleteStructure,
    } = context;

    const onDeleteFormType = async () => {
        if (!formType?.docId) return;
        setShowDeleteConfirm(false);
        await deleteStructure(formType.docId);
    };

    const handleOpenStateModal = () => {
        if (formType && 'possibleStates' in formType) {
            setShowStateSelectionModal(true);
        }
    };

    const onApplyStateChanges = async () => {
        if (!formType || !('possibleStates' in formType)) return;

        const selectedStates = Object.keys(layoutEditorState.possibleStates) as FormStateId[];
        handleStatesUpdate(selectedStates);
        setShowStateSelectionModal(false);
    };

    if (!formType || !('possibleStates' in formType)) {
        return (
            <FlexBox stretch justifyCenter>
                <Empty description="Select a Form Type to begin editing." />
            </FlexBox>
        );
    }

    const formStates = Object.keys(layoutEditorState.possibleStates) as FormStateId[];

    return (
        <FlexCol
            alignStart
            style={{ maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW }}
        >
            <FlexCol stretch>
                <FlexCol stretch>
                    <LayoutEditorDescription />
                    <FlexCol gap={0} noGrow>
                        <Text>
                            <Tooltip title={WorkflowEditorUIDescription.allowedNextForms.longDescription}>
                                Allowed Next Forms
                            </Tooltip>
                        </Text>
                        <Select
                            mode="multiple"
                            variant='filled'
                            placeholder="Allowed Next Forms"
                            style={{ width: '100%' }}
                            value={layoutEditorState.allowedNextForms}
                            onChange={handleAllowedFormsChange}
                            options={formTypes
                                ?.map(ft => ({
                                    label: ft.description?.shortLabel || ft.docId,
                                    value: ft.docId
                                })) || []}
                        />
                    </FlexCol>
                    <Table
                        size="small"
                        style={{ 
                            width: '100%',
                            marginTop: 20
                        }}
                        dataSource={formStates.map(state => ({
                            key: state,
                            state,
                            description: formStateDescription[state]?.longDescription || `Description for ${state} state`,
                            allowedNextStates: layoutEditorState.possibleStates[state]?.allowedNextStates || []
                        }))}
                        pagination={false}
                        columns={[
                            {
                                title: 'Form State',
                                dataIndex: 'state',
                                render: (state: FormStateId) => (
                                    <FlexCol gap={0}>
                                        <Text strong>{formStateDescription[state]?.shortLabel || state}</Text>
                                        <Text type="secondary">{formStateDescription[state]?.longDescription}</Text>
                                    </FlexCol>
                                )
                            },
                            {
                                title: 'Allowed Next States',
                                dataIndex: 'allowedNextStates',
                                render: (_, record) => (
                                    <Select
                                        mode="multiple"
                                        variant='filled'
                                        placeholder="Allowed Next States"
                                        style={{ width: '100%' }}
                                        value={layoutEditorState.possibleStates[record.state]?.allowedNextStates || []}
                                        onChange={(value) => handleStateChange(record.state, value)}
                                        options={formStates
                                            .filter(s => s !== record.state)
                                            .map(s => ({
                                                label: formStateDescription[s]?.shortLabel || s,
                                                value: s
                                            }))}
                                    />
                                )
                            }
                        ]}
                        footer={() => (
                            <Button
                                type="text"
                                icon={<Icon.Sliders />}
                                onClick={handleOpenStateModal}
                                block
                                style={{ maxWidth: 150 }}
                            >
                                Manage States
                            </Button>
                        )}
                    />
                </FlexCol>
                <Collapse
                    size="small"
                    style={{ width: '100%' }}
                    bordered={false}
                    items={[
                        {
                            key: 'danger',
                            label: 'Danger Zone',
                            children: (
                                <Button
                                    danger
                                    type="primary"
                                    icon={<DeleteOutlined />}
                                    onClick={() => setShowDeleteConfirm(true)}
                                >
                                    Delete Form Type
                                </Button>
                            ),
                        }
                    ]}
                />
            </FlexCol>
            <Modal
                title="Delete Form Type"
                open={showDeleteConfirm}
                onOk={onDeleteFormType}
                onCancel={() => setShowDeleteConfirm(false)}
                okText="Delete"
                okButtonProps={{ danger: true }}
            >
                <Text>Are you sure you want to delete this form type?</Text>
                <br />
                <Text type="danger">This action cannot be undone.</Text>
            </Modal>
            <Modal
                title="Manage Form States"
                open={showStateSelectionModal}
                onOk={onApplyStateChanges}
                onCancel={() => setShowStateSelectionModal(false)}
                okText="Apply Changes"
                width={'calc(min(100vw - 10px, 800px))'}
            >
                <Table
                    size="small"
                    pagination={false}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: Object.keys(layoutEditorState.possibleStates),
                        onSelect: (record: StateTableRecord, selected) => {
                            if (!selected) {
                                const { canDelete } = canRemoveState(record.state);
                                if (!canDelete) return;
                            }

                            const currentStates = Object.keys(layoutEditorState.possibleStates) as FormStateId[];
                            handleStatesUpdate(
                                selected
                                    ? [...currentStates, record.state]
                                    : currentStates.filter(s => s !== record.state)
                            );
                        },
                        onSelectAll: (selected, _selectedRows, changeRows: StateTableRecord[]) => {
                            const availableStates = changeRows
                                .filter(row => {
                                    if (!selected) {
                                        const { canDelete } = canRemoveState(row.state);
                                        return canDelete;
                                    }
                                    return true;
                                })
                                .map(row => row.state);

                            const currentStates = Object.keys(layoutEditorState.possibleStates) as FormStateId[];

                            handleStatesUpdate(
                                selected
                                    ? [...new Set([...currentStates, ...availableStates])]
                                    : currentStates.filter(s => {
                                        const { canDelete } = canRemoveState(s);
                                        return !canDelete || !availableStates.includes(s);
                                    })
                            );
                        },
                        getCheckboxProps: (record: StateTableRecord) => {
                            const { canDelete, reason } = canRemoveState(record.state);
                            return {
                                disabled: !canDelete,
                                title: !canDelete ? reason : undefined
                            };
                        }
                    }}
                    columns={[
                        {
                            title: 'State',
                            dataIndex: 'state',
                            key: 'state',
                            render: (state: FormStateId) => (
                                <Text strong>{formStateDescription[state]?.shortLabel || state}</Text>
                            )
                        },
                        {
                            title: 'Description',
                            dataIndex: 'state',
                            key: 'description',
                            render: (state: FormStateId) => (
                                <Text type="secondary">
                                    {formStateDescription[state]?.longDescription || `Description for ${state} state`}
                                </Text>
                            )
                        }
                    ]}
                    dataSource={Object.keys(formStateDescription).map(state => ({
                        key: state,
                        state: state as FormStateId
                    }))}
                />
            </Modal>
        </FlexCol>
    );
};

export default LayoutEditorFormDetails;