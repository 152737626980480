import { FC, useContext } from 'react';
import { Select, Typography, Tooltip } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FormContext } from '../../../providers/artifacts/FormProvider';
import { FormVersionContext } from '../../../providers/artifacts/FormVersionProvider';
import { formStateDescription } from '../../../types/System.Parameters.types';
import { TimeType } from '../../../types/System.types';
import FlexBox from '../../atoms/FlexBox';

dayjs.extend(relativeTime);

const { Text } = Typography;

/** 
 * Breadcrumb component for selecting form versions 
 * @param formId - The ID of the form
 */
const FormVersionSelect: FC = () => {
  const {
    selectedForm,
  } = useContext(FormContext);

  const {
    selectedFormVersion,
    formVersions,
    navigateToVersion
  } = useContext(FormVersionContext);

  const formatDate = (time: TimeType | undefined) => {
    if (time && 'toDate' in time) {
      const date = time.toDate();
      return {
        relative: dayjs(date).fromNow(),
        full: date.toLocaleString()
      };
    }
    return {
      relative: '',
      full: ''
    };
  };

  const handleVersionChange = (versionId: string) => {
    if (selectedForm.data?.docId) {
      navigateToVersion(selectedForm.data.docId, versionId);
    }
  };

  const versionOptions = formVersions.data?.map(version => {
    const created = formatDate(version.meta?.created);
    const modified = formatDate(version?.meta?.lastModified);
    const formNumber = version.meta?.formNumber;
    const formRevision = 'R' + (formNumber?.split('-R')[1] ?? '0');
    const formState = formStateDescription[version.state]?.shortLabel;

    return {
      value: version.docId || '',
      label: (
        <Tooltip title={formStateDescription[version.state]?.longDescription || ''}>
          <Text>
            <Text strong>{formState}</Text> | {formRevision}
          </Text>
          {modified.relative ? (
            <Text type="secondary">&nbsp;modified {modified.relative}</Text>
          ) : (
            <Text type="secondary">&nbsp;created {created.relative}</Text>
          )}
        </Tooltip>
      ),
      title: formState + ' | ' + formRevision
    };
  }) || [];

  return (
    <FlexBox>
      <Text>{selectedForm.data?.formNumber}</Text>
      <Select
        value={selectedFormVersion.data?.docId}
        optionLabelProp='title'
        onChange={handleVersionChange}
        options={versionOptions}
        style={{ minWidth: 120 }}
        dropdownStyle={{ width: 320 }}
      />
    </FlexBox>
  );
};

export default FormVersionSelect; 