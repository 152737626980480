import { FC, ReactNode, createContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useBoundCollection } from '../../use/data/useBoundCollection';
import { useBoundDoc } from '../../use/data/useBoundDoc';
import { Form, FormType, UILayout } from '../../types/System.types';
import { FieldViewVisibility } from '../../types/System.Parameters.types';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * @exports FormContext
 * Provides form-level data including the selected form, its type, and associated UI layouts.
 */
export interface FormContextType {
    selectedForm: ReturnType<typeof useBoundDoc<Form>>;
    formTypeOfSelectedForm: ReturnType<typeof useBoundDoc<FormType>>;
    selectedUILayout: ReturnType<typeof useBoundDoc<UILayout>>;
    uiLayouts: ReturnType<typeof useBoundCollection<UILayout>>;
    formTypes: ReturnType<typeof useBoundCollection<FormType>>;
    selectForm: (formId: string | undefined) => void;
    selectUILayout: (uiLayoutId: string | undefined) => void;
    insideFormContext: boolean;
    visibilityMode: FieldViewVisibility;
    setVisibilityMode: (mode: FieldViewVisibility) => void;
    navigateToForm: (formId: string) => void;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export const FormProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const { formId } = useParams<{ formId?: string }>();
    const [selectedFormId, selectForm] = useState<string | undefined>(undefined);
    const [selectedUILayoutId, selectUILayout] = useState<string | undefined>(undefined);
    const [visibilityMode, setVisibilityMode] = useState<FieldViewVisibility>('internal');
    const navigate = useNavigate();

    const selectedForm = useBoundDoc<Form>({
        path: 'forms',
        docId: selectedFormId,
        enabled: !!selectedFormId,
    });

    const formTypes = useBoundCollection<FormType>({
        path: 'formTypes',
        enabled: true,
    });

    const formTypeOfSelectedForm = useBoundDoc<FormType>({
        path: 'formTypes',
        docId: selectedForm.data?.formType,
        enabled: !!selectedForm.data?.formType,
    });

    const selectedUILayout = useBoundDoc<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        docId: selectedUILayoutId,
        enabled: !!selectedForm.data?.formType && !!selectedUILayoutId,
    });

    const uiLayouts = useBoundCollection<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        enabled: !!selectedForm.data?.formType,
    });

    const navigateToForm = useCallback((formId: string) => {
        navigate(`/forms/${formId}`);
        selectForm(formId);
    }, [navigate]);

    useEffect(() => {
        if (formId) {
            selectForm(formId);
        }
    }, [formId, selectForm]);

    const value = useMemo(() => ({
        selectedForm,
        formTypeOfSelectedForm,
        selectedUILayout,
        uiLayouts,
        formTypes,
        selectForm,
        selectUILayout,
        insideFormContext: true,
        visibilityMode,
        setVisibilityMode,
        navigateToForm,
    }), [
        selectedForm,
        formTypeOfSelectedForm,
        selectedUILayout,
        uiLayouts,
        formTypes,
        selectForm,
        selectUILayout,
        visibilityMode,
        setVisibilityMode,
        navigateToForm,
    ]);

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
};