import { FC, ReactNode, createContext, useContext } from 'react';
import { useLayoutEditorForm } from '../../use/editor/useLayoutEditorForm';
import { useLayoutEditorEntity } from '../../use/editor/useLayoutEditorEntity';

type LayoutEditorProviderProps = {
    type: 'form' | 'entity';
    children: ReactNode;
};

/**
 * Type for the drop position of an element with respect to the target (over) element
 */
export type DropBeforeInsideOrAfter = 'before' | 'inside' | 'after';

/**
 * Type-safe discriminated union of form and entity editor contexts
 */
export type LayoutEditorContextType = 
    | (ReturnType<typeof useLayoutEditorForm> & { type: 'form' })
    | (ReturnType<typeof useLayoutEditorEntity> & { type: 'entity' });

/**
 * Context for the Layout Editor functionality.
 * Provides a unified interface for both form and entity editing.
 */
export const LayoutEditorContext = createContext<LayoutEditorContextType | null>(null);

/**
 * Provider component that handles both form and entity editing contexts.
 * Uses type discrimination to ensure type safety and proper context usage.
 * 
 * @example
 * ```tsx
 * // For forms
 * <LayoutEditorProvider type="form">
 *   <YourComponent />
 * </LayoutEditorProvider>
 * 
 * // For entities
 * <LayoutEditorProvider type="entity">
 *   <YourComponent />
 * </LayoutEditorProvider>
 * ```
 */
export const LayoutEditorProvider: FC<LayoutEditorProviderProps> = (props) => {
    const formEditor = useLayoutEditorForm();
    const entityEditor = useLayoutEditorEntity();

    const contextValue: LayoutEditorContextType = props.type === 'form' 
        ? { ...formEditor, type: 'form' }
        : { ...entityEditor, type: 'entity' };

    return (
        <LayoutEditorContext.Provider value={contextValue}>
            {props.children}
        </LayoutEditorContext.Provider>
    );
};

/**
 * Hook to access the Layout Editor context.
 * Provides type-safe access to either form or entity editing functionality.
 * 
 * @throws {Error} If used outside of a LayoutEditorProvider
 * @returns Type-safe context value based on the provider type
 */
export const useLayoutEditorContext = () => {
    const context = useContext(LayoutEditorContext);
    if (!context) {
        throw new Error('useLayoutEditorContext must be used within a LayoutEditorProvider');
    }
    return context;
}; 