import { useState } from 'react';
import { useRequiredFields } from './useRequiredFields';
import { FieldViewVisibility } from '../../types/System.Parameters.types';
import { UIElement } from '../../types/System.types';

interface UseRequiredFieldsCheckParams {
    layoutStructure?: UIElement;
    formVersionFields?: Record<string, any>;
    formVersionEntityReferences?: { [entityId: string]: string | undefined };
    visibilityMode: FieldViewVisibility;
}

interface MissingField {
    fieldId: string;
    label: string;
    isVisibleInCurrentMode: boolean;
    isEntityReference: boolean;
}

export const useRequiredFieldsCheck = ({
    layoutStructure,
    formVersionFields,
    formVersionEntityReferences,
    visibilityMode
}: UseRequiredFieldsCheckParams) => {
    const [isRequiredFieldsModalOpen, setIsRequiredFieldsModalOpen] = useState(false);
    const [currentMissingFields, setCurrentMissingFields] = useState<MissingField[]>([]);

    const getMissingRequiredFields = useRequiredFields({
        layoutStructure,
        formVersionFields,
        formVersionEntityReferences,
        visibilityMode
    });

    const checkRequiredFields = () => {
        const missingFields = getMissingRequiredFields();
        
        if (missingFields.length > 0) {
            setCurrentMissingFields(missingFields);
            setIsRequiredFieldsModalOpen(true);
            return false;
        }
        return true;
    };

    const handleCloseRequiredFieldsModal = () => {
        setIsRequiredFieldsModalOpen(false);
    };

    return {
        checkRequiredFields,
        isRequiredFieldsModalOpen,
        currentMissingFields,
        handleCloseRequiredFieldsModal
    };
}; 