import { FC } from 'react';
import { Form, FormType } from '../types/System.types';
import { useTable } from '../use/useTable';
import { useBoundCollection } from '../use/data/useBoundCollection';

import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import Navb from '../components/Navb';
import FormsTable from '../components/artifacts/FormsTable';
import FormsTableControls from '../components/artifacts/FormsTableControls';

const FormsPage: FC = () => {
    const tableProps = useTable<Form>({
        path: 'forms',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
    });

    const formTypes = useBoundCollection<FormType>({
        path: 'formTypes',
        enabled: true
    });

    return (
        <FlexPage>
            <Navb />
            <FlexContent>
                <FormsTableControls tableProps={tableProps} />
                <FormsTable 
                    tableProps={tableProps} 
                    formTypes={formTypes.data}
                />
            </FlexContent>
        </FlexPage>
    );
};

export default FormsPage;

