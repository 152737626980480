import { useCallback, useEffect, useState } from 'react';
import { useGet } from './useGet';
import { 
  Form, 
  FormVersion, 
  GroupMember,
  UserDBRole 
} from '../../types/System.types';
import useFirebaseAuth from '../useFirebaseAuth';

/** Result of form access evaluation containing permissions and control functions */
interface FormAccessResult {
  /** Whether the user can read the form */
  canRead: boolean;
  /** Whether the user can write to the form */
  canWrite: boolean;
  /** Whether access check is in progress */
  loading: boolean;
  /** Any error that occurred during access check */
  error: Error | null;
  /** Function to manually trigger a permission re-check */
  refresh?: () => void;
}

/** Props for useEvaluateFormAccess hook */
interface UseEvaluateFormAccessProps {
  /** ID of the form to check access for */
  formId: string | undefined;
  /** ID of the form version to check access for */
  versionId: string | undefined;
  /** Whether to enable access checking */
  enabled?: boolean;
}

/**
 * Hook to evaluate form and version access based on Firestore rules
 * @returns Access evaluation result containing read/write permissions and refresh function
 */
export const useEvaluateFormAccess = ({
  formId,
  versionId,
  enabled = true
}: UseEvaluateFormAccessProps): FormAccessResult => {
  const { get } = useGet();
  const { user } = useFirebaseAuth();
  const [access, setAccess] = useState<FormAccessResult>({
    canRead: false,
    canWrite: false,
    loading: false,
    error: null
  });

  const checkAccess = useCallback(async () => {
    if (!formId || !enabled || !user?.uid) {
      setAccess({
        canRead: false,
        canWrite: false,
        loading: false,
        error: null
      });
      return;
    }

    setAccess(prev => ({ ...prev, loading: true }));

    try {
      const formDoc = await get<Form>('forms', formId);
      
      if (!formDoc) {
        setAccess({
          canRead: false,
          canWrite: false,
          loading: false,
          error: null
        });
        return;
      }

      const userRole = formDoc.userRoles?.[user.uid];
      const groupMember = formDoc.userGroup ? 
        await get<GroupMember>(`groups/${formDoc.userGroup}/groupMembers`, user.uid) :
        null;
      const groupRole = groupMember?.role;

      const hasRole = (minimumRole: UserDBRole): boolean => {
        const roles = [UserDBRole.viewer, UserDBRole.editor, UserDBRole.owner];
        const minIndex = roles.indexOf(minimumRole);
        const userRoleIndex = userRole ? roles.indexOf(userRole) : -1;
        const groupRoleIndex = groupRole ? roles.indexOf(groupRole) : -1;
        
        return userRoleIndex >= minIndex || groupRoleIndex >= minIndex;
      };

      const canRead = hasRole(UserDBRole.viewer);
      const hasWriteRole = hasRole(UserDBRole.editor);

      let canWrite = false;
      if (versionId && hasWriteRole) {
        const versionDoc = await get<FormVersion>(`forms/${formId}/formVersions`, versionId);
        canWrite = versionDoc?.state === 'draft';
      }

      setAccess({
        canRead,
        canWrite,
        loading: false,
        error: null
      });

    } catch (error) {
      setAccess({
        canRead: false,
        canWrite: false,
        loading: false,
        error: error as Error
      });
    }
  }, [formId, versionId, enabled, get, user?.uid]);

  useEffect(() => {
    checkAccess();
  }, [checkAccess]);

  return {
    ...access,
    refresh: checkAccess
  };
};