import { FC, useCallback, useState, useContext } from 'react';
import { Skeleton, Typography, Alert, Switch, Divider, Button, Input, Select, Tooltip, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined, BellOutlined, BellFilled, DeleteOutlined } from '@ant-design/icons';
import LayoutUI from './rendering/LayoutUI';
import UnmappedFieldsUI from './rendering/UnmappedFieldsUI';
import EntityRecordSelect from './atoms/EntityRecordSelect';
import FlexBox from './atoms/FlexBox';
import FlexCol from './atoms/FlexCol';
import { useFormAndEntityContexts } from '../use/useFormAndEntityContexts';
import { RecordStatus } from '../types/System.Parameters.types';
import { useEntityRecordField } from '../use/useEntityRecordField';
import { UserContext } from '../providers/UserProvider';

const { Text } = Typography;

interface EntityProps {
  disableRecordSelect?: boolean;
}

export const Entity: FC<EntityProps> = ({ disableRecordSelect = false }) => {
  const {
    selectedEntity: entity,
    selectedEntityRecord: entityRecord,
    hasEntityAccess,
    entityLocked,
    setEntityLocked,
    insideEntityContext,
    createNewEntityRecord: createNewRecord,
    debouncedSaveEntityRecord: debouncedSetRecord,
    selectEntityRecord,
    saveEntityReference,
    deleteRecord,
    recordSelectionLocked
  } = useFormAndEntityContexts();

  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { subscribeToEntityRecord, unsubscribeFromEntityRecord, user } = useContext(UserContext);

  const isSubscribed = user?.uid ? entityRecord?.notificationsSubscribers?.includes(user.uid) : false;

  const recordName = useEntityRecordField({
    initialValue: entityRecord?.name || '',
    onSave: debouncedSetRecord,
    fieldName: 'name',
    disabled: entityLocked
  });

  const recordStatus = useEntityRecordField({
    initialValue: entityRecord?.status || RecordStatus.Active,
    onSave: debouncedSetRecord,
    fieldName: 'status',
    disabled: entityLocked
  });

  const handleSelect = useCallback(async (recordId: string | undefined) => {
    await selectEntityRecord(recordId);
    saveEntityReference(recordId);
    setEntityLocked(true);
  }, [selectEntityRecord, saveEntityReference, setEntityLocked]);

  const handleSubscriptionToggle = async () => {
    if (!entity?.docId || !entityRecord?.docId) return;

    setIsSubscribing(true);
    try {
      if (isSubscribed) {
        await unsubscribeFromEntityRecord(entity.docId, entityRecord.docId);
      } else {
        await subscribeToEntityRecord(entity.docId, entityRecord.docId);
      }
    } finally {
      setIsSubscribing(false);
    }
  };

  const handleDelete = useCallback(async () => {
    if (!entityRecord?.docId) return;
    
    setIsDeleting(true);
    try {
      await deleteRecord(entityRecord.docId);
      setShowDeleteConfirm(false);
    } finally {
      setIsDeleting(false);
    }
  }, [deleteRecord, entityRecord?.docId]);

  const handleDeleteClick = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  if (!entity) {
    return <Skeleton active />;
  }

  if (!entity || !insideEntityContext) {
    return (
      <Alert
        message="Error: Missing entity data."
        type="error"
        style={{
          width: '100%',
        }}
      />
    );
  }

  const handleAddNewRecord = async () => {
    try {
      setIsCreating(true);
      const newRecordId = await createNewRecord();
      await selectEntityRecord(newRecordId);
      setEntityLocked(false);
    } catch (error) {
      console.error('Error creating new record:', error);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <FlexCol
      justifyStart
      alignStart
      style={{
        marginBottom: 5,
        width: '100%',
      }}
      gap={5}
      noGrow
    >
      <FlexCol
        stretch
        style={{ width: '100%' }}
        noGrow
      >
        {!disableRecordSelect && (
          <FlexBox
            justifyStart
            alignEnd
            stretch
            noGrow
            gap={10}
            style={{ marginTop: -6 }}
            wrap
          >

            <FlexCol noGrow gap={0}>
              <Text>
                {entity?.description?.shortLabel || entity?.description?.shortDescription || entity?.docId}&nbsp;
              </Text>
              <EntityRecordSelect
                entityId={entity.docId}
                recordId={entityRecord?.docId}
                onChange={handleSelect}
                onAddRecord={handleAddNewRecord}
                disabled={recordSelectionLocked}
                disableAddButton={!hasEntityAccess}
              />
            </FlexCol>
            <FlexCol noGrow gap={0}>
              <Text type={entityLocked ? undefined : 'warning'} style={{ whiteSpace: 'nowrap' }}>
                {entityLocked ? 'Edit ' : 'Editing '}
              </Text>
              <Tooltip
                title={!hasEntityAccess ? "You don't have edit access" : ""}
                open={!hasEntityAccess ? undefined : false}
              >
                <Switch
                  style={{
                    minWidth: 60,
                    marginTop: 5,
                  }}
                  checked={!entityLocked}
                  onChange={(value) => setEntityLocked(!value)}
                  disabled={!hasEntityAccess}
                />
              </Tooltip>
            </FlexCol>
            <Tooltip
              title={!hasEntityAccess ? "You don't have edit access" : ""}
              open={!hasEntityAccess ? undefined : false}
            >
              <Button
                type="link"
                onClick={handleAddNewRecord}
                loading={isCreating}
                icon={<PlusOutlined />}
                disabled={!hasEntityAccess}
              >
                Create
              </Button>
            </Tooltip>
            {entityRecord && (
              <Button
                type="link"
                onClick={handleSubscriptionToggle}
                icon={isSubscribed ? <BellFilled /> : <BellOutlined />}
                loading={isSubscribing}
              >
                {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
              </Button>
            )}
            {entityRecord && (
              <Tooltip
                title={!hasEntityAccess ? "You don't have edit access" : ""}
                open={!hasEntityAccess ? undefined : false}
              >
                <Button
                  type="link"
                  danger
                  onClick={handleDeleteClick}
                  loading={isDeleting}
                  icon={<DeleteOutlined />}
                  disabled={!hasEntityAccess}
                >
                  Delete
                </Button>
              </Tooltip>
            )}
          </FlexBox>
        )}
        {entityRecord && (
          <FlexBox gap={15} style={{ width: '100%' }} noGrow>
            <FlexCol gap={0} style={{ flex: '3' }}>
              <Text>
                Record Name {recordName.isSaving && <Typography.Text type='warning'><LoadingOutlined /></Typography.Text>}
              </Text>
              <Input
                variant='filled'
                placeholder="Record Name"
                value={recordName.value}
                onChange={(e) => recordName.onChange(e.target.value)}
                disabled={entityLocked}
                status={recordName.validationStatus}
              />
            </FlexCol>
            <FlexCol gap={0} style={{ flex: '1' }} >
              <Text>
                Status {recordStatus.isSaving && <Typography.Text type='warning'><LoadingOutlined /></Typography.Text>}
              </Text>
              <Select
                value={recordStatus.value}
                onChange={recordStatus.onChange}
                disabled={entityLocked}
                style={{ width: '100%' }}
                status={recordStatus.validationStatus}
                options={Object.values(RecordStatus).map(status => ({
                  value: status,
                  label: status
                }))}
              />
            </FlexCol>
          </FlexBox>
        )}
        <Divider
          style={{ margin: '10px 0' }}
        />
      </FlexCol>
      {entityRecord && entity?.defaultUILayout && (
        <LayoutUI
          uiElement={entity.defaultUILayout.structure}
        />
      )}
      {entityRecord && (
        <UnmappedFieldsUI
          uiElement={entity?.defaultUILayout?.structure}
        />
      )}
      {!entityRecord && (
        <Text>
          Select a record.
        </Text>
      )}
      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          danger: true,
          loading: isDeleting
        }}
      >
        <Typography.Text>
          Are you sure you want to delete "{entityRecord?.name}"? This action cannot be undone.
        </Typography.Text>
      </Modal>
    </FlexCol>
  );
};

export default Entity;