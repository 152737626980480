import { FC } from 'react';
import { Modal, Space, List, Typography } from 'antd';

const { Text } = Typography;

interface RequiredFieldsModalProps {
  missingFields: Array<{
    label: string;
    isVisibleInCurrentMode: boolean;
  }>;
  open: boolean;
  onClose: () => void;
}

export const FormRequiredFieldsModal: FC<RequiredFieldsModalProps> = ({ 
  missingFields,
  open,
  onClose
}) => {
  return (
    <Modal
      title="Missing Required Fields"
      open={open}
      onOk={onClose}
      onCancel={onClose}
      okText="Got it"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Text>Before you can proceed, fill in the following fields:</Text>
        <List
          size="small"
          dataSource={missingFields}
          renderItem={(field) => (
            <List.Item>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Space>
                  <Text strong>{field.label}</Text>
                  {!field.isVisibleInCurrentMode && (
                    <Text type="warning">
                      Switch to Internal view to access
                    </Text>
                  )}
                </Space>
              </Space>
            </List.Item>
          )}
        />
      </Space>
    </Modal>
  );
};

export default FormRequiredFieldsModal; 