/**
 * @file System.Parameters.types.ts
 * @description General system parameters: descriptions, limits, and constants.
 * 
 * Import this file directly into code, do not route via System.types.ts
 * 
 * WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
 * 
 */

import { Description } from "./System.types";

/**
 * Enum representing the status of entities in the system.
 */
export enum RecordStatus {
    Active = "Active",
    Inactive = "Inactive",
    Pending = "Pending",
    Archived = "Archived"
}

/**
 * Enum representing the visibility of a field in the system.
 */
export type FieldViewVisibility =
    'internal' 
    | 'external'
    | 'contractor';

/**
 * @constant FieldViewVisibilityDescription
 * @description Provides standardized descriptions for field view visibility
 * @readonly
 * 
 * Each visibility type has a description object with a shortLabel and longDescription
 */
export const FieldViewVisibilityDescription = {
    internal: {
        shortLabel: "Internal",
        longDescription: "Complete view with all internal fields and sensitive information. Shows all available fields regardless of visibility settings.",
        warning: "Warning: This view includes all internal notes and sensitive data. Be careful when sharing."
    },
    external: {
        shortLabel: "External",
        longDescription: "Standard view for external stakeholders. Hides internal fields and sensitive information. Only shows fields marked with external visibility.",
    },
    contractor: {
        shortLabel: "Contractor",
        longDescription: "Specialized view for contractors with relevant project details. Shows fields marked with contractor visibility."
    }
} as const;

/**
 * Type representing the possible states of a form in the system.
 */
export type FormStateId = 
    'draft' 
    | 'underReview' 
    | 'approved' 
    | 'revision' 
    | 'rejected' 
    | 'sent' 
    | 'closed' 
    | 'archived' 
    | 'paid' 
    | 'inTransit' 
    | 'delivered'
    | 'lost';

/**
 * @constant DescriptionLimits
 * @description Defines character length constraints for various text fields in the system
 * @readonly
 * 
 * @property {number} shortLabel - Maximum character length for abbreviated labels (10 characters)
 * @property {number} shortDescription - Maximum character length for brief descriptions (120 characters)
 * @property {number} longLabel - Maximum character length for full labels (25 characters)
 * @property {number} longDescription - Maximum character length for detailed descriptions (240 characters)
 */
export const DescriptionLimits = {
    shortLabel: 40,
    shortDescription: 120,
    longLabel: 80,
    longDescription: 240
} as const;

/**
 * @constant MAX_LENGTHS
 * @description Defines character length constraints for various text fields in the system
 * @readonly
 * 
 * @property {number} shortText - Maximum character length for short text fields (300 characters)
 * @property {number} longText - Maximum character length for long text fields (9000 characters)
 */
export const MaxLengths = {
    shortText: 300,
    longText: 9000
} as const;

/**
 * @constant MetaFieldUIDescription
 * @description Provides standardized descriptions for meta fields in the system
 * @readonly
 * 
 * Each meta field has a description object following the Description type structure
 */
export const MetaFieldUIDescription = {
    created: {
        shortLabel: "Created time",
        shortDescription: "Creation timestamp",
        longLabel: "Creation Timestamp",
        longDescription: "The exact date and time when this record was first created in the system."
    },
    lastModified: {
        shortLabel: "Modified time",
        shortDescription: "Last modification timestamp",
        longLabel: "Last Modified Timestamp",
        longDescription: "The most recent date and time when this record was modified in any way."
    },
    userId: {
        shortLabel: "Modified by",
        shortDescription: "Person who made changes",
        longLabel: "Modified By",
        longDescription: "Shows who made the most recent changes to this item"
    },
    agentType: {
        shortLabel: "Agent Type",
        shortDescription: "Type of creating agent",
        longLabel: "Creating Agent Type",
        longDescription: "Indicates whether this record was created by a human user, system process, or AI agent."
    },
    version: {
        shortLabel: "Version",
        shortDescription: "Record version number",
        longLabel: "Record Version Number",
        longDescription: "The version number of this record, indicating its position in the revision history."
    },
    formNumber: {
        shortLabel: "Form Number",
        shortDescription: "Unique identifier for the form",
        longLabel: "Form Number",
        longDescription: "A unique identifier for the form, automatically generated by the system."
    }
} as const;

/**
 * @constant DEFAULT_SEARCH_HITS_PER_PAGE
 * @description Defines the default number of items to display per page in paginated lists
 * @readonly
 * 
 * @type {number}
 * @default 20
 */
export const DEFAULT_SEARCH_HITS_PER_PAGE = 20;

/**
 * @constant DEFAULT_TABLE_CHUNK_SIZE
 * @description Defines the default number of items to load at a time in chunked table views
 * @readonly
 * 
 * @type {number}
 * @default 20
 */
export const DEFAULT_TABLE_CHUNK_SIZE = 20;

/**
 * @constant MAX_LIMITED_WIDTH_VIEWPORT
 * @description Defines the maximum width for a viewport that should have a limited width
 * @readonly
 * 
 * @type {number}
 */
export const MAX_LIMITED_WIDTH_VIEWPORT = 1200;

/**
 * @constant MAX_LIMITED_WIDTH_VIEWPORT_NARROW
 * @description Defines the maximum width for a viewport that should have a narrow width
 * @readonly
 * 
 * @type {number}
 */
export const MAX_LIMITED_WIDTH_VIEWPORT_NARROW = 900;

/**
 * @constant WorkflowEditorUIDescription
 * @description Provides standardized descriptions for workflow editor fields
 * @readonly
 * 
 * Each field has a description object with a longDescription explaining its purpose
 */
export const WorkflowEditorUIDescription = {
    formType: {
        longDescription: "Select the type of form to configure its workflow states and transitions. Each form type has its own set of states and rules."
    },
    formTypeConfiguration: {
        longDescription: "Configure the workflow settings for the selected form type"
    },
    states: {
        longDescription: "List of all possible states in this form type's workflow. States represent different stages in the form's lifecycle, such as Draft, Submitted, or Approved."
    },
    stateConfiguration: {
        longDescription: "Configure the selected state's behavior, including its display labels, description, and allowed transitions to other states."
    },
    shortLabel: {
        longDescription: "A brief, identifying label for this state that appears in dropdowns and compact displays. Limited to 25 characters."
    },
    shortDescription: {
        longDescription: "A concise explanation of this state's purpose and when it should be used. Limited to 120 characters."
    },
    allowedNextStates: {
        longDescription: "Define which states this form can transition to from its current state. This controls the workflow progression and available actions."
    },
    allowedNextForms: {
        longDescription: "Select which form types can be created after this form. This defines the possible workflow progressions between different form types."
    },
    rules: {
        longDescription: "View the entry and exit rules that control when a form can enter or leave this state. Rules enforce workflow requirements and validations."
    }
} as const;

export const MetricsEditorUIDescription = {
    metricType: {
        shortLabel: "Metric Editor",
        longDescription: "Select the metric you want to edit."
    },
    metricConfiguration: {
        longDescription: "Configure the metric settings for the selected metric"
    },
    status: {
        shortLabel: "Status",
        longDescription: "The current status of this metric (e.g., Active, Inactive, and Archived)"
    },
    source: {
        parentFormType: {
            shortLabel: "Metric Source (Form)",
            longDescription: "Select the form to use for the source of the metric"
        },
        formVersion: {
            shortLabel: "Form States",
            longDescription: "Choose which form state(s) to include in the metric (e.g., Draft, Submitted, Approved)"
        },
        valueField: {
            shortLabel: "Quantity Field",
            longDescription: "Select a form field that contains the quantity for the metric"
        },
        timeField: {
            shortLabel: "Time Field",
            longDescription: "Select a form field that contains the timestamp for the metric"
        }
    },
    statistic: {
        shortLabel: "Statistic Type",
        longDescription: "The type of statistical calculation to perform on the source data (e.g., count, sum, average)"
    },
    groupBy: {
        shortLabel: "Entities to Group By",
        longDescription: "Select entities to group the metric results by. This allows breaking down the metric by different categories."
    },
    description: {
        shortLabel: {
            shortLabel: "Metric Name",
            longDescription: "A brief and unique identifying name for this metric"
        },
        longDescription: {
            shortLabel: "Description",
            longDescription: "A detailed explanation of what this metric measures and how it's used"
        }
    },
    timeSettings: {
        timePeriods: {
            shortLabel: "Time Periods",
            longDescription: "Select the time periods over which to calculate this metric (e.g., day, week, month, quarter, year)"
        },
        frequencyOfCalculation: {
            shortLabel: "Calculation Frequency",
            longDescription: "How often the system should update this metric's values"
        }
    }
} as const;

/**
 * @constant SPACING
 * @description Defines standardized spacing values for consistent UI layout
 * @readonly
 * 
 * @property {number} xxs - Extra small spacing for minimal separation (5px)
 * @property {number} xs - Basic spacing between related elements (10px)
 * @property {number} sm - Spacing between fields in same section (20px)
 * @property {number} md - Medium section separation (30px)
 * @property {number} lg - Large section separation (40px)
 */
export const SPACING = {
    xxs: 5,  
    xs: 10,  
    sm: 20,  
    md: 30,  
    lg: 40   
} as const;

/*
 * Form states description
 */
export const formStateDescription: Record<FormStateId, Description> = {
    ['draft']: {
        shortLabel: "Draft",
        longDescription: "Form is in draft mode, editable by the creator"
    },
    ['underReview']: {
        shortLabel: "Under Review",
        longDescription: "Form is currently being reviewed by authorized personnel"
    },
    ['approved']: {
        shortLabel: "Approved",
        longDescription: "Form has been approved and is considered finalized"
    },
    ['revision']: {
        shortLabel: "Revision",
        longDescription: "Form is being revised based on feedback"
    },
    ['rejected']: {
        shortLabel: "Rejected",
        longDescription: "Form has been rejected and requires revision"
    },
    ['sent']: {
        shortLabel: "Sent",
        longDescription: "Form has been sent to the relevant parties"
    },
    ['closed']: {
        shortLabel: "Closed",
        longDescription: "This form process is complete and closed"
    },
    ['archived']: {
        shortLabel: "Archived",
        longDescription: "Form is no longer active but retained for record-keeping"
    },
    ['paid']: {
        shortLabel: "Paid",
        longDescription: "Payment for the form has been received and processed"
    },
    ['inTransit']: {
        shortLabel: "In Transit",
        longDescription: "Items or services are currently in transit"
    },
    ['delivered']: {
        shortLabel: "Delivered",
        longDescription: "Items or services have been delivered to the destination"
    },
    ['lost']: {
        shortLabel: "Lost",
        longDescription: "Form has been marked as a lost opportunity and is no longer active"
    }
};

/**
 * Default form columns to use in the form list
 */
export const formColumns = [
    { title: 'ID', key: 'docId' },
    { title: 'Title', key: 'title' },
    { title: 'Form Number', key: 'formNumber' },
    { title: 'Form Type', key: 'formType' },
    { title: 'Created', key: 'meta.created' },
] as const;

/**
 * Form types that are allowed to be shown in the Add menu dropdown
 * TODO REMOVE WHEN FORM ENTRY/EXIT RULES ARE IMPLEMENTED SYD-459
 * https://linear.app/syncretic/issue/SYD-459/entryexit-form-rules-function-calls
 */
export const ALLOWED_ADD_MENU_FORM_TYPES = ['InDesign', 'Quote'] as const;

/**
 * Default timezone for CLW's system
 */
export const defaultTimeZone = 'America/Chicago';

/**
 * Default frequency of calculation for metrics
 */
export const defaultFrequencyOfCalculation = 'daily' as const;

/**
 * Default startMetricTimeWindow to use in metrics for CLW's system
 */
export const defaultMetricStartTimeWindow = '2021-01-01T06:00:00+00:00';