import { FC, ReactNode, useContext } from 'react';
import { EntityContext, EntityContextValue } from '../artifacts/EntityProvider';
import { EntityRecordContext, EntityRecordContextValue } from '../artifacts/EntityRecordProvider';
import { LayoutEditorContext } from './LayoutEditorProvider';
import { RecordStatus } from '../../types/System.Parameters.types';
import { Timestamp } from 'firebase/firestore';

/**
 * Creates a mock entity context for preview purposes.
 * This context simulates an entity environment with read-only permissions
 * and basic entity structure for previewing layouts in the editor.
 * 
 * @param {EditorUILayout} editorLayout - The current layout being edited
 * @returns {EntityContextValue} A mock entity context for preview
 */
const createPreviewEntityContext = (editorLayout: any): EntityContextValue => ({
    entities: [],
    hasEntityAccess: true,
    selectedEntity: {
        defaultUILayout: editorLayout,
        description: {
            shortLabel: 'Preview Entity',
            shortDescription: 'Preview Mode'
        },
        status: RecordStatus.Active,
        userRoles: {},
        meta: {
            created: Timestamp.now(),
            modified: Timestamp.now(),
            status: 'active'
        }
    },
    selectEntity: async () => { },
    entityLocked: true,
    setEntityLocked: () => { },
    insideEntityContext: true,
    setSelectedEntityId: () => { },
    clearEntity: () => { },
    parentEntityContext: null,
});

const createPreviewRecordContext = (): EntityRecordContextValue => ({
    entityRecords: [],
    selectedEntityRecord: {
        name: 'Preview Record',
        fields: {},
        entityReferences: {},
        status: RecordStatus.Active,
        meta: {
            created: Timestamp.now(),
            modified: Timestamp.now(),
            status: 'active'
        }
    },
    selectEntityRecord: async () => { },
    setRecord: () => { },
    deleteRecord: async () => { },
    debouncedSetRecord: () => { },
    createNewRecord: async () => { return ''; },
    clearEntityRecord: () => { },
});

/**
 * PreviewEntityProvider creates a simulated entity context for previewing
 * entity layouts in the editor. It wraps the editor's current layout
 * in a mock entity context that allows for realistic preview rendering.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - Child components to render within the preview context
 * @returns {JSX.Element} The provider component with preview context
 * @throws {Error} If used outside of LayoutEditorProvider context
 */
export const PreviewEntityProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const editor = useContext(LayoutEditorContext);
    
    if (!editor) {
        throw new Error('PreviewEntityProvider must be used within a LayoutEditorProvider');
    }

    const entityValue = createPreviewEntityContext(editor.layout);
    const recordValue = createPreviewRecordContext();

    return (
        <EntityContext.Provider value={entityValue}>
            <EntityRecordContext.Provider value={recordValue}>
                {children}
            </EntityRecordContext.Provider>
        </EntityContext.Provider>
    );
}; 