import { FC, useState, useContext } from 'react';
import {
    Button,
    Select,
    Dropdown,
    MenuProps,
    Tooltip,
    message,
    Segmented,
    DatePicker,
    theme
} from 'antd';
import {
    DownOutlined,
} from '@ant-design/icons';
import { WhereFilterOp } from 'firebase/firestore';
import { createForm } from '../../services/artifactFunctions';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../atoms/FlexBox';
import { UseTableResult } from '../../use/useTable';
import { Form } from '../../types/System.types';
import { useFormTypes } from '../../use/useFormTypes';
import { UserContext } from '../../providers/UserProvider';
import { FormFilterMode } from '../../use/views/useViewFormsByEntity';
import { ALLOWED_ADD_MENU_FORM_TYPES } from '../../types/System.Parameters.types'; // TODO REMOVE WHEN FORM ENTRY/EXIT RULES ARE IMPLEMENTED SYD-459
import dayjs from 'dayjs';

interface FormsTableControlsProps {
    tableProps: UseTableResult<Form>;
}

const FormsTableControls: FC<FormsTableControlsProps> = ({ tableProps }) => {
    const { token } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const formTypes = useFormTypes();
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const [selectedFormType, setSelectedFormType] = useState<string>('');
    const [filterMode, setFilterMode] = useState<FormFilterMode>('all');
    const [selectedDateRange, setSelectedDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);

    /**
     * Updates filters based on current form type and mode
     * @param newFormType The new form type filter
     * @param newMode The new filter mode
     * @param newDateRange The new date range filter
     */
    const updateFilters = (
        newFormType: string,
        newMode: FormFilterMode,
        newDateRange: [dayjs.Dayjs, dayjs.Dayjs] | null
    ) => {
        const baseFilters = [];

        const formTypeFilter = newFormType
            ? [{
                field: 'formType',
                operator: '==' as WhereFilterOp,
                value: newFormType
            }]
            : [];
        baseFilters.push(...formTypeFilter);

        const userFilter = user?.uid ? (
            newMode === 'subscribed' ? [{
                field: 'notificationsSubscribers',
                operator: 'array-contains' as WhereFilterOp,
                value: user.uid
            }] : newMode === 'assigned' ? [{
                field: 'assignedTo',
                operator: '==' as WhereFilterOp,
                value: user.uid
            }] : []
        ) : [];
        baseFilters.push(...userFilter);

        if (newDateRange && newDateRange[0] && newDateRange[1]) {
            baseFilters.push({
                field: 'meta.lastModified',
                operator: '>=' as WhereFilterOp,
                value: newDateRange[0].toDate(),
            });
            baseFilters.push({
                field: 'meta.lastModified',
                operator: '<=' as WhereFilterOp,
                value: newDateRange[1].toDate(),
            });
        }

        tableProps.setFilters(baseFilters);
        tableProps.returnToStart();
    };

    const handleFormTypeChange = (value: string) => {
        setSelectedFormType(value);
        updateFilters(value, filterMode, selectedDateRange);
    };

    const handleModeChange = (mode: FormFilterMode) => {
        setFilterMode(mode);
        updateFilters(selectedFormType, mode, selectedDateRange);
    };

    const handleDateRangeChange = (
        dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
        dateStrings: [string, string]
    ) => {
        setSelectedDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs] | null);
        updateFilters(selectedFormType, filterMode, dates as [dayjs.Dayjs, dayjs.Dayjs] | null);
    };

    const handleCreateForm = async (formTypeId: string) => {
        setIsCreating(true);
        try {
            const selectedFormType = Object.values(formTypes.data).find(type => type.docId === formTypeId);
            const newFormId = await createForm({
                formType: formTypeId,
                title: `New ${selectedFormType?.description?.shortLabel || 'Form'}`,
            });
            messageApi.success('New form created successfully');
            navigate(`/forms/${newFormId}`);
        } catch (error) {
            console.error('Error creating new form:', error);
            messageApi.error('Failed to create new form');
        } finally {
            setIsCreating(false);
        }
    };

    const createItems: MenuProps['items'] = [
        {
            type: 'group',
            label: 'Forms',
            children: Object.values(formTypes.data)
                // #### TODO REMOVE WHEN FORM ENTRY/EXIT RULES ARE IMPLEMENTED SYD-459
                // https://linear.app/syncretic/issue/SYD-459/entryexit-form-rules-function-calls
                .filter((formType): formType is typeof formType & { docId: typeof ALLOWED_ADD_MENU_FORM_TYPES[number] } =>
                    formType.docId != null && ALLOWED_ADD_MENU_FORM_TYPES.includes(formType.docId as typeof ALLOWED_ADD_MENU_FORM_TYPES[number])
                )
                .map(formType => ({
                    key: formType.docId,
                    label: (
                        <Tooltip title={formType.description?.longDescription}>
                            {formType.description?.shortLabel || 'Unnamed Form Type'}
                        </Tooltip>
                    ),
                    onClick: () => handleCreateForm(formType.docId)
                }))
        }
    ];

    return (
        <FlexBox stretch noGrow wrap>
            {contextHolder}
            <FlexBox noGrow wrap>
                <Segmented
                    style={{ backgroundColor: token.colorFillTertiary }}
                    value={filterMode}
                    onChange={(value) => handleModeChange(value as FormFilterMode)}
                    options={[
                        { label: 'All Forms', value: 'all' },
                        { label: 'Subscribed', value: 'subscribed' },
                        { label: 'Assigned', value: 'assigned' }
                    ]}
                />
                <Select
                    value={selectedFormType}
                    onChange={handleFormTypeChange}
                    style={{ width: 150 }}
                    dropdownStyle={{ width: 320 }}
                    options={[
                        { value: '', label: <em>All types</em> },
                        ...Object.values(formTypes.data).map((t) => ({
                            value: t.docId || '',
                            label: t.description?.longLabel || t.description?.shortLabel || 'Unnamed Form Type'
                        })) || []
                    ]}
                    variant='filled'
                />
                <DatePicker.RangePicker
                    value={selectedDateRange}
                    onChange={handleDateRangeChange}
                    style={{ marginLeft: 10 }}
                />
            </FlexBox>
            <Dropdown
                menu={{ items: createItems }}
                trigger={['click']}
                disabled={formTypes.loading || formTypes?.data.length === 0 || isCreating}
                overlayStyle={{ width: 220 }}
            >
                <Button
                    disabled={formTypes.loading || formTypes?.data.length === 0 || isCreating}
                    type='primary'
                    loading={isCreating}
                >
                    Create
                    <DownOutlined />
                </Button>
            </Dropdown>
        </FlexBox>
    );
};

export default FormsTableControls;