import { ComponentProps, FC, useContext } from 'react';
import { Cascader, theme, Typography } from 'antd';
import type { CascaderProps } from 'antd';
import { 
    ColumnWidthOutlined,
    ColumnHeightOutlined,
    PlusOutlined,
    InfoCircleOutlined 
} from '@ant-design/icons';

import { fieldDataType } from '../../../types/System.FieldTypes.types';
import { builtInFieldType } from '../../../types/System.BuiltInFieldTypes.types';
import { configFieldType } from '../../../types/CLW.FieldTypes.types';
import { histCLWTypesToSunset } from '../../../types/CLW.HistoricalFieldTypes.types';
import { UIElementType } from '../../../types/System.types';
import { EditorUIContainer, EditorUIElement } from '../../../types/System.types';
import { LayoutEditorContext } from '../../../providers/editor/LayoutEditorProvider';
import { MetaFieldUIDescription } from '../../../types/System.Parameters.types';

let idCounter = 0;

/**
 * Generates a unique ID for new elements
 * @returns {string} A unique identifier prefixed with 'a'
 */
const generatedId = () => `a${idCounter++}`;

const { Text } = Typography;

interface AddElementMenuProps extends Partial<CascaderProps> {}

/**
 * NewAddElementMenu is a cascading dropdown menu component that allows users to add
 * different types of elements to the layout editor. It supports adding containers
 * (rows/columns), built-in fields, CLW fields, entity references, and meta fields.
 * 
 * @component
 * @param {CascaderProps} props - Ant Design Cascader component props
 * @returns {JSX.Element | null} The rendered NewAddElementMenu component or null if no editor context
 */
export const AddElementMenu: FC<AddElementMenuProps> = ({
    ...props
}) => {
    const editor = useContext(LayoutEditorContext);
    const { token } = theme.useToken();

    if (!editor) return null;

    const {
        selectedElement,
        layout,
        addElement,
        structure,
        entities
    } = editor;

    const isContainer = (element: EditorUIElement): element is EditorUIContainer => 
        element.uiElementType === UIElementType.Column || 
        element.uiElementType === UIElementType.Row || 
        element.uiElementType === UIElementType.Grid;

    const targetContainer = selectedElement && isContainer(selectedElement)
        ? selectedElement 
        : layout.structure as EditorUIContainer;

    if (!targetContainer) return null;

    const handleAddContainer = (elementType: UIElementType.Grid | UIElementType.Row | UIElementType.Column) => {
        const newContainer: EditorUIContainer = {
            uiElementType: elementType,
            children: [],
            props: {},
            dndId: generatedId()
        };

        addElement(newContainer, targetContainer);
    };

    const handleAddField = (fieldTypeId: string) => {
        const newElement: EditorUIElement = {
            fieldId: fieldTypeId + '-' + generatedId(),
            uiElementType: UIElementType.Field,
            fieldTypeId,
            props: {},
            dndId: generatedId()
        } as EditorUIElement;

        addElement(newElement, targetContainer);
    };

    const handleAddEntity = (entityId: string) => {
        const newElement: EditorUIElement = {
            uiElementType: UIElementType.EntityReference,
            entityId,
            props: {},
            dndId: generatedId()
        } as EditorUIElement;

        addElement(newElement, targetContainer);
    };

    const handleAddMetaField = (metaFieldId: string) => {
        const newElement: EditorUIElement = {
            uiElementType: UIElementType.MetaField,
            metaFieldId,
            props: {},
            dndId: generatedId()
        } as EditorUIElement;

        addElement(newElement, targetContainer);
    };

    const systemAndBuiltInOptions = [
        ...Object.entries(fieldDataType).map(([id, field]) => ({
            value: `system-${id}`,
            label: field.description?.shortLabel || id,
        })),
        ...Object.entries(builtInFieldType).map(([id, field]) => ({
            value: `builtin-${id}`,
            label: field.description?.shortLabel || id,
        }))
    ];

    const clwFieldOptions = Object.entries(configFieldType).map(([id, field]) => ({
        value: `clw-${id}`,
        label: field.description?.shortLabel || id,
    }));

    const referenceOptions = entities.map(entity => ({
        value: `reference-${entity.docId}`,
        label: entity.description?.shortLabel || 'Unnamed',
    }));

    const metaFieldOptions = Object.entries(MetaFieldUIDescription).map(([id, field]) => ({
        value: `meta-${id}`,
        label: field.shortLabel || id,
    }));

    const historicalClwFieldOptions = Object.entries(histCLWTypesToSunset).map(([id, field]) => ({
        value: `hist-${id}`,
        label: field.description?.shortLabel || id,
    }));

    const options: CascaderProps['options'] = [
        {
            value: UIElementType.Row,
            label: 'Row Container',
            icon: <ColumnWidthOutlined />
        },
        {
            value: UIElementType.Column,
            label: 'Column Container',
            icon: <ColumnHeightOutlined />
        },
        {
            label: 'Built-in Fields',
            value: 'builtin-fields',
            children: systemAndBuiltInOptions
        },
        {
            label: 'CLW Fields',
            value: 'clw-fields',
            children: clwFieldOptions
        },
        {
            label: 'Records',
            value: 'entities',
            children: referenceOptions
        },
        {
            label: 'Meta Fields',
            value: 'meta-fields',
            icon: <InfoCircleOutlined />,
            children: metaFieldOptions
        },
        {
            label: 'Historical CLW Fields',
            value: 'hist-fields',
            children: historicalClwFieldOptions
        },
    ];

    const onChange = (value: string[]) => {
        if (!value || value.length === 0) return;
        
        const selectedValue = value[value.length - 1];
        
        if (selectedValue === UIElementType.Row || selectedValue === UIElementType.Column) {
            handleAddContainer(selectedValue);
        } else if (selectedValue.startsWith('system-') || 
                   selectedValue.startsWith('builtin-') || 
                   selectedValue.startsWith('clw-') ||
                   selectedValue.startsWith('hist-')) {
            const fieldId = selectedValue.split('-')[1];
            handleAddField(fieldId);
        } else if (selectedValue.startsWith('reference-')) {
            const id = selectedValue.split('-')[1];
            handleAddEntity(id);
        } else if (selectedValue.startsWith('meta-')) {
            const metaFieldId = selectedValue.split('-')[1];
            handleAddMetaField(metaFieldId);
        }
    };

    return (
        <Cascader
            options={options}
            onChange={onChange as any}
            placeholder={<Text>Add to layout...</Text>}
            style={{ 
                width: '200px', 
                ...props.style 
            }}
            variant={'outlined'}
            changeOnSelect={false}
            displayRender={(labels) => labels[labels.length - 1]}
            showSearch={{
                filter: (inputValue, path) => {
                    return path.some(
                        option => (option.label as string)
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                    );
                }
            }}
            expandTrigger="hover"
            dropdownMatchSelectWidth={false}
            disabled={!structure}
            suffixIcon={<PlusOutlined style={{ color: token.colorPrimary }} />}
            size={props.size}
        />
    );
}; 