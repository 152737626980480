import { FC, useContext, useState } from 'react';
import { 
    Button, 
    Tooltip, 
    Select, 
    Modal, 
    Segmented,
    theme,
    Divider,
    message
} from 'antd';
import {
    EyeInvisibleOutlined,
    DesktopOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import FlexBox from '../atoms/FlexBox';
import { FormType, Entity } from '../../types/System.types';
import { AddElementMenu } from './element/AddElementMenu';
import Icon from '../atoms/Icon';

const { useToken } = theme;

/**
 * Toolbar component for the layout editor providing controls for layout management.
 * Features include:
 * - Layout/Details mode switching
 * - Structure selection dropdown
 * - Save/Undo/Redo operations
 * - Preview mode controls
 * @component
 */
export const LayoutEditorToolbar: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingId, setPendingId] = useState<string | null>(null);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { token } = useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const editor = useContext(LayoutEditorContext);
    if (!editor) throw new Error('EditorToolbar must be used within LayoutEditorProvider');

    const {
        type,
        structures,
        structure,
        isModified,
        selectStructure,
        save,
        undo,
        redo,
        mode,
        setMode,
        previewMode,
        setPreviewMode,
        createStructure
    } = editor;

    const handleSave = async () => {
        if (isSaving) return;
        setIsSaving(true);
        try {
            await save();
            messageApi.success('Saved successfully');
        } catch (error) {
            console.error('Error saving:', error);
            messageApi.error('Failed to save');
            throw error;
        } finally {
            setIsSaving(false);
        }
    };

    const getSelectItems = () => {
        return structures?.map((item: FormType | Entity) => ({
            label: item.description?.shortLabel || item.docId,
            value: item.docId
        }));
    };

    const getCurrentValue = () => {
        return structure?.docId;
    };

    const handleSelect = async (value: string) => {
        if (value === 'new') {
            try {
                const newId = await createStructure();
                await selectStructure(newId);
                setIsSelectOpen(false);
                setMode('Details');
                messageApi.success(`New ${type} created successfully`);
            } catch (error) {
                console.error('Error creating structure:', error);
                messageApi.error(`Failed to create new ${type}`);
                throw error;
            }
            return;
        }

        if (isModified) {
            setIsModalOpen(true);
            setPendingId(value);
        } else {
            try {
                await selectStructure(value);
            } catch (error) {
                console.error('Error selecting structure:', error);
                messageApi.error(`Failed to select ${type}`);
                throw error;
            }
        }
    };

    const handleModalConfirm = async () => {
        if (!pendingId) return;
        
        try {
            await selectStructure(pendingId);
        } catch (error) {
            console.error('Error selecting structure:', error);
            messageApi.error(`Failed to select ${type}`);
            throw error;
        } finally {
            setIsModalOpen(false);
            setPendingId(null);
        }
    };

    return (
        <FlexBox
            id='editor-toolbar'
            justifyStart
            stretch
            noGrow
            gap={10}
            wrap
        >
            {contextHolder}
            <FlexBox noGrow wrap>
                <Segmented
                    style={{ backgroundColor: token.colorFillTertiary }}
                    options={[
                        { label: 'Layout', value: 'Layout' },
                        { label: editor.type === 'form' ? 'Rules' : 'Description', value: 'Details' }
                    ]}
                    value={mode}
                    onChange={value => {
                        setMode(value as 'Layout' | 'Details');
                        if (value !== 'Layout') {
                            setPreviewMode('off');
                        }
                    }}
                />
                <Select
                    variant='filled'
                    style={{ width: 250 }}
                    value={getCurrentValue()}
                    onChange={handleSelect}
                    placeholder={editor.type === 'form' ? 'Select Form Type' : 'Select Record Type'}
                    open={isSelectOpen}
                    onDropdownVisibleChange={setIsSelectOpen}
                    options={getSelectItems()}
                    showSearch
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Button
                                type="text"
                                icon={<PlusOutlined />}
                                block
                                onClick={() => handleSelect('new')}
                            >
                                Create {editor.type === 'form' ? 'Form Type' : 'Record Type'}...
                            </Button>
                        </>
                    )}
                />
            </FlexBox>
            {mode === 'Layout' && <AddElementMenu />}
            <FlexBox justifyStart>
                <Tooltip title="Save changes">
                    <Button
                        type="link"
                        icon={<Icon.Save />}
                        disabled={!isModified}
                        loading={isSaving}
                        onClick={handleSave}
                        size='small'
                    >
                        Save
                    </Button>
                </Tooltip>
                <Tooltip title="Undo last change">
                    <Button
                        type='link'
                        icon={<Icon.RotateCcw />}
                        disabled={!isModified}
                        onClick={undo}
                        size='small'
                    >
                        Undo
                    </Button>
                </Tooltip>
                <Tooltip title="Redo last change">
                    <Button
                        type='link'
                        icon={<Icon.RotateCw />}
                        disabled={!isModified}
                        onClick={redo}
                        size='small'
                    >
                        Redo
                    </Button>
                </Tooltip>
            </FlexBox>
            <Segmented
                style={{ backgroundColor: token.colorFillTertiary }}
                options={[
                    { value: 'off', icon: <EyeInvisibleOutlined /> },
                    { value: 'desktop', icon: <DesktopOutlined /> }
                ]}
                value={previewMode}
                onChange={value => setPreviewMode(value as 'off' | 'desktop')}
                disabled={mode === 'Details'}
            />
            <Modal
                title="Unsaved Changes"
                open={isModalOpen}
                onOk={handleModalConfirm}
                onCancel={() => {
                    setIsModalOpen(false);
                    setPendingId(null);
                }}
                okText="Switch"
                cancelText="Cancel"
            >
                You have unsaved changes. Switching layouts will lose these changes. Continue?
            </Modal>
        </FlexBox>
    );
}; 