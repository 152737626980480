import { useCallback } from 'react';
import { 
    UIElement, 
    UIField, 
    UIEntityReference, 
    UIElementType,
    fieldType 
} from '../../types/System.types';
import { FieldViewVisibility } from '../../types/System.Parameters.types';

interface RequiredFieldsParams {
    layoutStructure?: UIElement;
    formVersionFields?: Record<string, any>;
    formVersionEntityReferences?: { [entityId: string]: string | undefined };
    visibilityMode: FieldViewVisibility;
}

interface MissingField {
    fieldId: string;
    label: string;
    isVisibleInCurrentMode: boolean;
    isEntityReference: boolean;
}

/**
 * Hook to handle required fields validation in forms
 * @param params Parameters needed for validation
 * @returns Function to get missing required fields
 */
export const useRequiredFields = ({
    layoutStructure,
    formVersionFields,
    formVersionEntityReferences,
    visibilityMode
}: RequiredFieldsParams) => {
    const getMissingRequiredFields = useCallback((): MissingField[] => {
        // Early return if we don't have structure
        if (!layoutStructure) {
            return [];
        }

        // Get all fields and entity references from layout
        const getElements = (element: UIElement): Array<UIField | UIEntityReference> => {
            if (element.uiElementType === UIElementType.Field || 
                element.uiElementType === UIElementType.EntityReference) {
                return [element];
            } else if ('children' in element) {
                return element.children.flatMap(getElements);
            }
            return [];
        };

        // Get required elements
        const requiredElements = getElements(layoutStructure)
            .filter(element => element.required);

        // Check which required elements don't exist in form data
        const missingFields = requiredElements
            .map(element => {
                if (element.uiElementType === UIElementType.Field) {
                    const ft = fieldType[element.fieldTypeId];
                    return {
                        fieldId: element.fieldId,
                        label: ft?.description?.shortLabel || element.fieldId,
                        isVisibleInCurrentMode: !ft?.visibility || 
                                              ft.visibility.length === 0 || 
                                              visibilityMode === 'internal' ||
                                              ft.visibility.includes(visibilityMode),
                        isEntityReference: false
                    };
                } else {
                    // Handle entity reference
                    const entityId = element.entityId || '';
                    return {
                        fieldId: entityId,
                        label: `${entityId} Record`,
                        isVisibleInCurrentMode: true, // Entity references are always visible
                        isEntityReference: true
                    };
                }
            })
            .filter(field => {
                const fieldId = field.fieldId;
                if (field.isEntityReference) {
                    return !formVersionEntityReferences?.[fieldId];
                } else {
                    // Check fields
                    return !formVersionFields?.[fieldId];
                }
            });

        return missingFields;
    }, [layoutStructure, formVersionFields, formVersionEntityReferences, visibilityMode]);

    return getMissingRequiredFields;
}; 
