/**
 * EntityContext provides entity-related data and actions to components.
 * It manages entities, entity records, selection, and CRUD operations.
 */
import React, { createContext, useState, useCallback, useMemo, FC, useContext } from 'react';
import { useBoundCollection } from '../../use/data/useBoundCollection';
import { useBoundDoc } from '../../use/data/useBoundDoc';
import { Entity, DocId, UserDBRole } from '../../types/System.types';
import { UserContext } from '../UserProvider';

/** Context for managing entity-related state and operations */
export interface EntityContextValue {
    /** List of available entities */
    entities: Entity[];
    /** Whether user has entity edit permissions */
    hasEntityAccess: boolean;
    /** Currently selected entity */
    selectedEntity: Entity | null;
    /** Select an entity by ID */
    selectEntity: (entityId: string | undefined) => Promise<void>;
    /** Whether entity is locked for editing */
    entityLocked: boolean;
    /** Set entity locked state */
    setEntityLocked: (value: boolean) => void;
    /** Whether component is inside entity context */
    insideEntityContext: boolean;
    /** Set selected entity ID */
    setSelectedEntityId: (entityId: string | undefined) => void;
    /** Clear selected entity */
    clearEntity: () => void;
    /** Parent entity context if nested within another entity */
    parentEntityContext: EntityContextValue | null;
}

export const EntityContext = createContext<EntityContextValue>({} as EntityContextValue);

/** Props for EntityProvider component */
interface EntityProviderProps {
    /** Initial entity ID to select */
    initialEntityId?: DocId;
    /** Child components */
    children: React.ReactNode;
}

/**
 * Provider component for entity-related state and operations
 * @example
 * <EntityProvider initialEntityId="entity123">
 *   <YourComponent />
 * </EntityProvider>
 */
export const EntityProvider: FC<EntityProviderProps> = ({ children, initialEntityId }) => {
    const [selectedEntityId, setSelectedEntityId] = useState<DocId | undefined>(initialEntityId);
    const [entityLocked, setEntityLocked] = useState(true);

    const {
        userGroups
    } = useContext(UserContext);
    const parentEntityContext = useContext(EntityContext);

    const hasEntityAccess = useMemo(() => {
        return userGroups['entityAdmins'] === UserDBRole.editor ||
            userGroups['entityAdmins'] === UserDBRole.owner ||
            userGroups['entityEditors'] === UserDBRole.editor ||
            userGroups['entityEditors'] === UserDBRole.owner;
    }, [userGroups]);

    const {
        data: entities = [],
    } = useBoundCollection<Entity>({
        path: 'entities',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
        initialLimit: 50,
    });

    const {
        data: selectedEntity,
    } = useBoundDoc<Entity>({
        path: 'entities',
        docId: selectedEntityId,
        enabled: !!selectedEntityId,
    });

    const selectEntity = useCallback(async (entityId: string | undefined) => {
        setSelectedEntityId(entityId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearEntity = useCallback(() => {
        setSelectedEntityId(undefined);
    }, [setSelectedEntityId]);

    const contextValue = useMemo(
        () => ({
            entities,
            hasEntityAccess,
            selectedEntity: selectedEntity || null,
            selectEntity,
            entityLocked,
            setEntityLocked,
            insideEntityContext: true,
            setSelectedEntityId,
            clearEntity,
            parentEntityContext: parentEntityContext?.insideEntityContext ? parentEntityContext : null,
        }),
        [
            entities,
            hasEntityAccess,
            selectedEntity,
            selectEntity,
            entityLocked,
            setEntityLocked,
            setSelectedEntityId,
            clearEntity,
            parentEntityContext,
        ]
    );

    return <EntityContext.Provider value={contextValue}>
        {children}
    </EntityContext.Provider>;
};