/**
 * @fileoverview Renders a form field UI component with validation, tooltips, and real-time updates.
 */

import React, { FC, useState, useContext } from 'react';
import { Typography, Tooltip, Alert } from 'antd';
import { UIField, fieldType } from '../../types/System.types';
import FlexCol from '../atoms/FlexCol';
import ControlUI from './ControlUI';
import { useFieldUIState } from '../../use/useFieldUIState';
import { LoadingOutlined } from '@ant-design/icons';
import { useFieldUIContext } from '../../use/useFieldUIContext';
import { FieldViewVisibility } from '../../types/System.Parameters.types';
import { FormContext } from '../../providers/artifacts/FormProvider';

const { Text } = Typography;

/**
 * Represents the possible validation states for a field.
 * - 'error': Field has invalid input
 * - 'warning': Field has concerning but valid input
 * - '': Field is in a neutral state
 * - undefined: Field has not been validated
 */
export type ValidationStatus = 'error' | 'warning' | '' | undefined;

/**
 * Props for the FieldUI component
 */
interface FieldUIProps {
  /** Configuration object containing field properties and metadata */
  uiField: UIField;
}

/**
 * A form field component that handles rendering, validation, and data updates.
 * 
 * @component
 * @memo
 * 
 * @features
 * - Real-time validation with visual feedback
 * - Context-aware data persistence (Entity or Form context)
 * - Tooltip-based help text and error messages
 * - Read-only mode support
 * - Automatic field type detection and rendering
 * - Debounced updates to prevent excessive writes
 * 
 * @context
 * - Requires either EntityContext or FormContext
 * - Also uses UserContext for event logging
 * 
 * @throws {Alert}
 * Renders an error Alert if neither EntityContext nor FormContext is available
 */
const FieldUI: FC<FieldUIProps> = React.memo(({
  uiField
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const { 
    hasContext,
    isReadOnly, 
    fieldData, 
    handleDBSave 
  } = useFieldUIContext(uiField);
  
  const { visibilityMode } = useContext(FormContext);
  const ft = fieldType[uiField.fieldTypeId];
  const {
    value,
    setValue,
    validationStatus,
    isSaving,
  } = useFieldUIState({
    initialValue: fieldData?.value,
    handleDBSave,
    validation: ft?.validation,
    tempId: uiField.fieldId,
  });

  if (!hasContext) {
    return <Alert message="Error: No context is available." type="error" />;
  }

  const shouldShowField = () => {
    if (!ft?.visibility || ft.visibility.length === 0) {
      return true;
    }

    if (visibilityMode === 'internal') {
      return true;
    }

    return ft.visibility.includes(visibilityMode);
  };

  if (!shouldShowField()) {
    return null;
  }

  const label = ft?.description?.shortLabel || uiField.fieldId;
  const description = ft?.description?.longDescription ||
    ft?.description?.shortDescription ||
    ft?.description?.longLabel ||
    ft?.description?.shortLabel;

  return (
    <FlexCol gap={0} {...uiField.props}>
      <Tooltip title={description}>
        <Text>
          {label} {isSaving && <Text type='warning'><LoadingOutlined /></Text>}
          {uiField.required && (
            <Text type={validationStatus === 'error' ? 'danger' : 'secondary'}>
              {' '}*
            </Text>
          )}
        </Text>
      </Tooltip>
      <ControlUI
        field={fieldData}
        value={value}
        uiField={uiField}
        onChange={setValue}
        readOnly={isReadOnly}
        onFocus={() => {
          setIsFocused(true);
          setShowPopover(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          setShowPopover(false);
        }}
        status={validationStatus}
        style={{ width: '100%' }}
        {...ft.props}
      />
      <Tooltip
        color='red'
        title={
          <div onClick={() => setShowPopover(false)}>
            {ft?.errorMessage}
          </div>
        }
        open={!!validationStatus && showPopover && isFocused}
        placement='bottomLeft'
      />
    </FlexCol>
  );
});

FieldUI.displayName = 'FieldUI';

export default FieldUI;