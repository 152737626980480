import { useState, useCallback, useEffect } from 'react';
import { useBoundCollection } from './data/useBoundCollection';
import { ListOrderBy, ListFilters } from '../providers/DataProvider';
import { DocDataWithId } from '../types/System.types';
import { DEFAULT_TABLE_CHUNK_SIZE } from '../types/System.Parameters.types';

export interface UseTableResult<T extends DocDataWithId> {
    tableData: T[];
    isLoading: boolean;
    error: Error | null;
    loadMore: () => void;
    returnToStart: () => void;
    refresh: () => void;
    setFilters: (filters: ListFilters) => void;
    setOrderBy: (orderBy: ListOrderBy) => void;
    hasMore: boolean;
    isFirstPage: boolean;
    currentOrderBy?: ListOrderBy;
    totalCount: number;
    loadedCount: number;
}

interface UseTableParams {
    path: string;
    chunkSize?: number;
    maxItems?: number;
    initialOrderBy?: ListOrderBy;
    sortableFields?: string[];
}

export const useTable = <T extends DocDataWithId>({
    path,
    chunkSize = DEFAULT_TABLE_CHUNK_SIZE,
    initialOrderBy = [{ field: 'meta.lastModified', direction: 'desc' }],
}: UseTableParams): UseTableResult<T> => {
    const [loadedData, setLoadedData] = useState<T[]>([]);
    const [isFirstPage, setIsFirstPage] = useState(true);
    const [currentOrderBy, setCurrentOrderBy] = useState<ListOrderBy>(initialOrderBy);
    
    const {
        data: newData,
        error,
        loading: isLoading,
        refresh,
        setStartAt,
        setFilters: setBoundFilters,
        setOrderBy: setBoundOrderBy,
    } = useBoundCollection<T>({
        path,
        initialLimit: chunkSize + 1,
        initialOrderBy,
    });

    const hasMore = newData.length > chunkSize;

    useEffect(() => {
        if (!isLoading && newData.length > 0 && loadedData.length === 0) {
            const dataToAdd = newData
                .slice(0, chunkSize)
                .map(item => ({ ...item, key: item.docId }));
            setLoadedData(dataToAdd);
        }
    }, [isLoading, newData, loadedData.length, chunkSize]);

    const loadMore = useCallback(() => {
        if (!isLoading && newData.length > 0) {
            const startIndex = loadedData.length;
            const dataToAdd = newData
                .slice(0, chunkSize)
                .map(item => ({
                    ...item,
                    key: `${item.docId}-${startIndex}`
                }));
            setLoadedData(prev => [...prev, ...dataToAdd]);
            setIsFirstPage(false);
            if (hasMore) {
                setStartAt(dataToAdd[dataToAdd.length - 1]);
            }
        }
    }, [newData, chunkSize, isLoading, hasMore, setStartAt, loadedData.length]);

    const returnToStart = useCallback(() => {
        setLoadedData([]);
        setIsFirstPage(true);
        setStartAt(undefined);
    }, [setStartAt]);

    const setFilters = useCallback((filters: ListFilters) => {
        setBoundFilters(filters);
        setLoadedData([]);
        setIsFirstPage(true);
        setStartAt(undefined);
    }, [setBoundFilters, setStartAt]);

    const setOrderBy = useCallback((orderBy: ListOrderBy) => {
        setCurrentOrderBy(orderBy);
        setBoundOrderBy(orderBy);
        setLoadedData([]);
        setIsFirstPage(true);
        setStartAt(undefined);
    }, [setBoundOrderBy, setStartAt]);

    return {
        tableData: loadedData,
        isLoading,
        error,
        loadMore,
        returnToStart,
        refresh,
        setFilters,
        setOrderBy,
        hasMore,
        isFirstPage,
        currentOrderBy,
        totalCount: newData.length,
        loadedCount: loadedData.length
    };
};