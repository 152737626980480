import { FC } from 'react';
import EntityUI from './EntityUI';
import { EntityProvider } from '../../providers/artifacts/EntityProvider';
import { EntityRecordProvider } from '../../providers/artifacts/EntityRecordProvider';
import { SearchWrapper } from '../SearchWrapper';
import { useFormAndEntityContexts } from '../../use/useFormAndEntityContexts';
import { UIElement } from '../../types/System.types';

interface EntityUIContextProps {
    entityId?: string;
    uiElement: UIElement;
}

const EntityUIContext: FC<EntityUIContextProps> = ({ entityId, uiElement }) => {
    const { 
        data
    } = useFormAndEntityContexts();

    if (!entityId) return null;

    const recordId = data?.entityReferences?.[entityId];

    return (
        <EntityProvider initialEntityId={entityId}>
            <EntityRecordProvider initialRecordId={recordId}>
                <SearchWrapper
                    index="entityRecord"
                    filters={`docIdParent:${entityId}`}
                >
                    <EntityUI uiElement={uiElement} />
                </SearchWrapper>
            </EntityRecordProvider>
        </EntityProvider>
    );
};

export default EntityUIContext;