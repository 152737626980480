import { Reducer } from 'react';
import { Active, Over } from '@dnd-kit/core';

import { 
    UILayout, 
    UIElement, 
    UIElementType, 
    Description, 
    EditorUILayout, 
    EditorUIElement, 
    EditorUIContainer,
    FormType,
} from '../types/System.types';
import { FormStateId } from '../types/System.Parameters.types';
/**
 * State interface for managing the layout editor's state.
 */
export interface LayoutEditorState {
    /** Currently selected element in the editor */
    selectedElement: EditorUIElement | null;
    /** Current layout being edited */
    layout: EditorUILayout;
    /** Description of the layout */
    description: Description;
    /** Form states configuration */
    possibleStates: FormType['possibleStates'];
    /** Allowed next form types */
    allowedNextForms: FormType['allowedNextForms'];
    /** Undo/redo history stacks */
    history: {
        past: Array<{
            layout: EditorUILayout;
            possibleStates: FormType['possibleStates'];
            allowedNextForms: FormType['allowedNextForms'];
            description: Description;
            selectedElement: EditorUIElement | null;
        }>;
        future: Array<{
            layout: EditorUILayout;
            possibleStates: FormType['possibleStates'];
            allowedNextForms: FormType['allowedNextForms'];
            description: Description;
            selectedElement: EditorUIElement | null;
        }>;
    };
}

/**
 * Checks if a UI element type is a container type (Grid, Row, or Column).
 * Used to determine if elements can be dropped into it during drag and drop operations.
 * 
 * @param elementType - The type of UI element to check
 * @returns True if the element type is a container type
 */
const isContainerType = (elementType: UIElementType): boolean => 
    [UIElementType.Grid, UIElementType.Row, UIElementType.Column].includes(elementType);

/**
 * Assigns unique dndIds to all elements in a layout recursively.
 * Used when initializing a new layout or selecting a form type.
 * 
 * @param layout - The layout to process
 * @returns A new layout with dndIds assigned to all elements
 */
const assignElementIds = (layout: UILayout): EditorUILayout => {
    let counter = 0;

    const traverse = (element: UIElement): EditorUIElement => {
        if (element.uiElementType === UIElementType.Grid || 
            element.uiElementType === UIElementType.Row || 
            element.uiElementType === UIElementType.Column) {
            return {
                ...element,
                dndId: `e${counter++}`,
                children: element.children.map(traverse)
            } as EditorUIElement;
        }
        
        return {
            ...element,
            dndId: `e${counter++}`
        } as EditorUIElement;
    };
    
    return {
        ...layout,
        structure: traverse(layout.structure)
    };
};

/**
 * Finds a container element by its dndId in the layout structure.
 * Used during drag and drop operations to locate source and target containers.
 * 
 * @param layout - The layout to search in
 * @param containerId - The dndId of the container to find
 * @returns The found container or null if not found
 */
const findContainerById = (layout: EditorUILayout, containerId: string): EditorUIContainer | null => {
    const traverse = (element: EditorUIElement): EditorUIContainer | null => {
        if (element.dndId === containerId) {
            return element as EditorUIContainer;
        }
        
        if ('children' in element && element.children) {
            for (const child of element.children) {
                const result = traverse(child);
                if (result) return result;
            }
        }
        return null;
    };
    
    return traverse(layout.structure);
};

/**
 * Finds an element by its dndId in the layout structure.
 * Used during drag and drop operations to locate elements.
 * 
 * @param layout - The layout to search in
 * @param elementId - The dndId of the element to find
 * @returns The found element or null if not found
 */
const findElementById = (layout: EditorUILayout, elementId: string): EditorUIElement | null => {
    const traverse = (element: EditorUIElement): EditorUIElement | null => {
        if (element.dndId === elementId) {
            return element;
        }
        
        if ('children' in element && element.children) {
            for (const child of element.children) {
                const result = traverse(child);
                if (result) return result;
            }
        }
        return null;
    };
    
    return traverse(layout.structure);
};

/**
 * Union type for all possible actions that can be dispatched to the layout editor reducer.
 * Each action type corresponds to a specific operation in the layout editor.
 */
export type LayoutEditorAction =
    /** Selects and initializes a new layout */
    | { type: 'SELECT_LAYOUT'; payload: UILayout }
    /** Handles drag and drop operations between containers */
    | { type: 'DROP_ELEMENT'; payload: { active: Active; over: Over }}
    /** Updates the currently selected element */
    | { type: 'SELECT_ELEMENT'; payload: EditorUIElement | null }
    /** Removes an element from its container */
    | { type: 'REMOVE_ELEMENT'; payload: {
        container: EditorUIContainer;
        elementDndId: string;
      }}
    /** Adds a new element to a container */
    | { type: 'ADD_ELEMENT'; payload: {
        element: EditorUIElement;
        container?: EditorUIContainer;
      }}
    /** Updates a field's property */
    | { type: 'UPDATE_FIELD_PROPERTY'; payload: {
        elementDndId: string;
        property: 'required';  // For now, we only support updating the required property
        value: boolean;
      }}
    /** Reverts to the previous layout state */
    | { type: 'UNDO' }
    /** Reapplies a previously undone layout state */
    | { type: 'REDO' }
    /** Updates the description of the selected form type */
    | { type: 'UPDATE_DESCRIPTION'; payload: Description }
    /** Updates the form states configuration */
    | { type: 'FORM_STATES_CONFIG'; payload: {
        type: 'load';
        possibleStates: FormType['possibleStates'];
        allowedNextForms: FormType['allowedNextForms'];
      } | {
        type: 'update';
        states: FormStateId[];
      } | {
        type: 'updateNextStates';
        stateId: FormStateId;
        allowedNextStates: FormStateId[];
      } | {
        type: 'updateNextForms';
        forms: FormType['docId'][];
      }}
    /** Resets the layout state to initial state */
    | { type: 'RESET_LAYOUT' }
    /** Marks the current state as saved */
    | { type: 'SAVE_COMPLETE' }
    /** Selects and initializes a new structure with its layout and states */
    | { type: 'SELECT_STRUCTURE'; payload: {
        layout: UILayout;
        description?: Description;
        possibleStates?: FormType['possibleStates'];
        allowedNextForms?: FormType['allowedNextForms'];
    }};

/**
 * Initial state for the layout editor.
 * Creates an empty column container as the root element.
 */
export const initialLayoutEditorState: LayoutEditorState = {
    selectedElement: null,
    description: {} as Description,
    layout: {
        structure: {
            uiElementType: UIElementType.Column,
            children: [],
            dndId: 'e0'
        } as EditorUIContainer,
    },
    possibleStates: {
        draft: {
            allowedNextStates: ['draft']
        }
    },
    allowedNextForms: [],
    history: {
        past: [],
        future: []
    }
};

/**
 * Creates a snapshot of the current editor state for history tracking
 */
const createStateSnapshot = (state: LayoutEditorState) => ({
    layout: state.layout,
    possibleStates: state.possibleStates,
    allowedNextForms: state.allowedNextForms,
    description: state.description,
    selectedElement: state.selectedElement
});

/**
 * Reducer function for managing layout editor state.
 * Handles all layout modifications, element selection, and history management.
 * 
 * Key features:
 * - Maintains undo/redo history
 * - Handles drag and drop operations
 * - Manages element selection
 * - Processes layout modifications
 * 
 * @param state - Current state of the layout editor
 * @param action - Action to process
 * @returns New state after processing the action
 */
export const layoutEditorReducer: Reducer<LayoutEditorState, LayoutEditorAction> = (
    state: LayoutEditorState,
    action: LayoutEditorAction
): LayoutEditorState => {
    switch (action.type) {
        case 'SELECT_LAYOUT':
            return {
                ...state,
                layout: assignElementIds(action.payload),
                selectedElement: null,
                history: {
                    past: [],
                    future: []
                }
            };

        case 'FORM_STATES_CONFIG': {
            switch (action.payload.type) {
                case 'load':
                    return {
                        ...state,
                        possibleStates: action.payload.possibleStates,
                        allowedNextForms: action.payload.allowedNextForms || [],
                        history: {
                            past: [],
                            future: []
                        }
                    };
                    
                case 'update': {
                    const newPossibleStates: FormType['possibleStates'] = {
                        draft: state.possibleStates.draft 
                    };
                    
                    action.payload.states.forEach(stateId => {
                        if (stateId === 'draft') return;
                        newPossibleStates[stateId] = state.possibleStates[stateId] || {
                            allowedNextStates: []
                        };
                    });
                    
                    return {
                        ...state,
                        possibleStates: newPossibleStates,
                        history: {
                            past: [...state.history.past, createStateSnapshot(state)],
                            future: []
                        }
                    };
                }
                case 'updateNextStates':
                    return {
                        ...state,
                        possibleStates: {
                            ...state.possibleStates,
                            [action.payload.stateId]: {
                                ...state.possibleStates[action.payload.stateId],
                                allowedNextStates: action.payload.allowedNextStates
                            }
                        },
                        history: {
                            past: [...state.history.past, createStateSnapshot(state)],
                            future: []
                        }
                    };
                case 'updateNextForms':
                    return {
                        ...state,
                        allowedNextForms: action.payload.forms,
                        history: {
                            past: [...state.history.past, createStateSnapshot(state)],
                            future: []
                        }
                    };
            }
        }

        case 'RESET_LAYOUT':
            return initialLayoutEditorState;

        case 'DROP_ELEMENT': {
            const { active, over } = action.payload;
            if (!over) return state;

            const overElement = over.data.current?.elementId ? 
                findElementById(state.layout, over.data.current.elementId) : 
                null;
            const isOverContainer = overElement && isContainerType(overElement.uiElementType);
            
            const newLayout = structuredClone(state.layout);
            
            const sourceContainer = findContainerById(
                newLayout, 
                active.data.current?.container
            );

            const targetContainer = isOverContainer ?
                findContainerById(newLayout, over.data.current?.elementId) :
                findContainerById(newLayout, over.data.current?.container);
                
            if (!sourceContainer || !targetContainer) return state;

            const elementToMove = findElementById(newLayout, active.id.toString());
            if (!elementToMove) return state;

            // First remove from source
            sourceContainer.children = sourceContainer.children.filter(
                child => 'dndId' in child && child.dndId !== elementToMove.dndId
            ) as EditorUIElement[];

            // Then add to target at correct index
            const targetIndex = over.data.current?.sortable?.index ?? targetContainer.children.length;
            targetContainer.children = [
                ...targetContainer.children.slice(0, targetIndex),
                elementToMove,
                ...targetContainer.children.slice(targetIndex)
            ] as EditorUIElement[];

            return {
                ...state,
                layout: newLayout,
                history: {
                    past: [...state.history.past, createStateSnapshot(state)],
                    future: []
                }
            };
        }

        case 'REMOVE_ELEMENT': {
            const { container, elementDndId } = action.payload;
            const newLayout = structuredClone(state.layout);
            
            const targetContainer = findContainerById(newLayout, container.dndId);
            if (targetContainer) {
                const elementIndex = targetContainer.children.findIndex(
                    (child: EditorUIElement) => child.dndId === elementDndId
                );
                if (elementIndex !== -1) {
                    targetContainer.children.splice(elementIndex, 1);
                }
            }

            return {
                ...state,
                selectedElement: null,
                layout: newLayout,
                history: {
                    past: [...state.history.past, createStateSnapshot(state)],
                    future: []
                }
            };
        }

        case 'SELECT_ELEMENT':
            return {
                ...state,
                selectedElement: action.payload
            };

        case 'ADD_ELEMENT': {
            const { element, container } = action.payload;
            const newLayout = structuredClone(state.layout);
            const isContainer = isContainerType(element.uiElementType);

            if (container) {
                const targetContainer = findContainerById(newLayout, container.dndId);
                if (targetContainer) {
                    targetContainer.children = targetContainer.children || [];
                    targetContainer.children.unshift(element);
                }
            } 
            else if (state.selectedElement) {
                if (isContainer) {
                    const selectedContainer = findContainerById(newLayout, state.selectedElement.dndId);
                    if (selectedContainer) {
                        selectedContainer.children = selectedContainer.children || [];
                        selectedContainer.children.unshift(element);
                    }
                } else {
                    const traverse = (container: EditorUIContainer): boolean => {
                        if (!container.children) {
                            container.children = [];
                        }
                        
                        const index = container.children.findIndex(child => 
                            (child as EditorUIElement).dndId === state.selectedElement?.dndId
                        );
                        
                        if (index !== -1) {
                            container.children.splice(index + 1, 0, element);
                            return true;
                        }
                        
                        return container.children.some(child => 
                            isContainerType(child.uiElementType) && traverse(child as EditorUIContainer)
                        );
                    };
                    
                    traverse(newLayout.structure as EditorUIContainer);
                }
            } 
            else {
                const rootContainer = newLayout.structure as EditorUIContainer;
                if (!rootContainer.children) {
                    rootContainer.children = [];
                }
                rootContainer.children.unshift(element);
            }
            
            return {
                ...state,
                layout: newLayout,
                history: {
                    past: [...state.history.past, createStateSnapshot(state)],
                    future: []
                }
            };
        }

        case 'UPDATE_FIELD_PROPERTY': {
            const { elementDndId, property, value } = action.payload;
            if (property !== 'required' || typeof value !== 'boolean') {
                return state;
            }

            // Find the element to update
            const updateElement = (element: EditorUIElement): EditorUIElement => {
                if (element.dndId === elementDndId) {
                    if (element.uiElementType === UIElementType.Field || 
                        element.uiElementType === UIElementType.EntityReference) {
                        return {
                            ...element,
                            required: value
                        };
                    }
                    return element;
                }

                if ('children' in element && element.children) {
                    return {
                        ...element,
                        children: element.children.map(updateElement)
                    };
                }

                return element;
            };

            const updatedLayout = {
                ...state.layout,
                structure: updateElement(state.layout.structure)
            };

            return {
                ...state,
                layout: updatedLayout,
                history: {
                    past: [...state.history.past, createStateSnapshot(state)],
                    future: []
                }
            };
        }

        case 'UNDO':
            if (state.history.past.length === 0) return state;
            const previous = state.history.past[state.history.past.length - 1];
            return {
                ...state,
                layout: previous.layout,
                possibleStates: previous.possibleStates,
                allowedNextForms: previous.allowedNextForms,
                description: previous.description,
                selectedElement: previous.selectedElement,
                history: {
                    past: state.history.past.slice(0, -1),
                    future: [createStateSnapshot(state), ...state.history.future]
                }
            };

        case 'REDO':
            if (state.history.future.length === 0) return state;
            const next = state.history.future[0];
            return {
                ...state,
                layout: next.layout,
                possibleStates: next.possibleStates,
                allowedNextForms: next.allowedNextForms,
                description: next.description,
                selectedElement: next.selectedElement,
                history: {
                    past: [...state.history.past, createStateSnapshot(state)],
                    future: state.history.future.slice(1)
                }
            };

        case 'UPDATE_DESCRIPTION':
            return {
                ...state,
                description: {
                    ...state.description,
                    ...action.payload
                },
                history: {
                    past: [...state.history.past, createStateSnapshot(state)],
                    future: []
                }
            };

        case 'SAVE_COMPLETE':
            return {
                ...state,
                history: {
                    past: [],
                    future: []
                }
            };

        case 'SELECT_STRUCTURE':
            return {
                ...initialLayoutEditorState,
                layout: assignElementIds(action.payload.layout),
                description: action.payload.description || {},
                possibleStates: action.payload.possibleStates || {
                    draft: {
                        allowedNextStates: ['draft']
                    }
                },
                allowedNextForms: action.payload.allowedNextForms || [],
                history: {
                    past: [],
                    future: []
                }
            };

        default:
            return state;
    }
}; 