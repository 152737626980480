import { FC, useState } from 'react';
import { Typography, Button, Steps, Tag, Divider, Badge, Alert, Segmented, theme, Empty } from 'antd';
import { Link } from 'react-router-dom';
import { ExpandOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import FlexCol from '../atoms/FlexCol';
import FlexBox from '../atoms/FlexBox';
import { Timestamp } from 'firebase/firestore';
import { FormVersion } from '../../types/System.types';
import { formStateDescription } from '../../types/System.Parameters.types';
import { useViewFormsByEntity, FormFilterMode } from '../../use/views/useViewFormsByEntity';
import FlexCard from '../atoms/FlexCard';
import { EntityRecordModal } from '../modals/EntityRecordModal';

dayjs.extend(relativeTime);

const { Title, Text } = Typography;
const { useToken } = theme;
/**
 * Maps form state to Steps status
 */
const mapFormStateToStepStatus = (state?: FormVersion['state']): 'wait' | 'process' | 'finish' | 'error' => {
  switch (state) {
    case 'draft':
      return 'wait';

    case 'underReview':
    case 'inTransit':
      return 'process';

    case 'sent':
    case 'approved':
    case 'closed':
    case 'delivered':
    case 'paid':
    case 'archived':
      return 'finish';

    case 'revision':
    case 'rejected':
      return 'error';

    default:
      return 'wait';
  }
};

const getStateTagColor = (state?: FormVersion['state']): string => {
  switch (state) {
    case 'draft':
      return 'blue';

    case 'underReview':
    case 'inTransit':
      return 'cyan';

    case 'sent':
    case 'approved':
    case 'closed':
    case 'delivered':
    case 'paid':
      return 'success';

    case 'archived':
      return 'purple';

    case 'revision':
    case 'rejected':
      return 'red';

    case 'revision':
      return 'volcano';

    default:
      return 'blue';
  }
};

/**
 * Displays forms grouped by their associated projects
 */
export const ViewFormsByEntity: FC<{ entityType: string }> = ({ entityType }) => {
  const {
    groupedForms,
    error,
    loadMore,
    hasMore,
    toggleNotification,
    filterMode,
    setFilterMode,
    loading
  } = useViewFormsByEntity(entityType);

  const { token } = useToken();

  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const [selectedRecordId, setSelectedRecordId] = useState<string>();
  const FORMS_PER_GROUP = 3;

  if (error) {
    return (
      <Alert
        message="Error loading forms"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <FlexCol>
      <FlexBox noGrow>
        <Segmented
          style={{ backgroundColor: token.colorFillTertiary }}
          value={filterMode}
          onChange={(value) => setFilterMode(value as FormFilterMode)}
          options={[
            { label: 'All Forms', value: 'all' },
            { label: 'Subscribed', value: 'subscribed' },
            { label: 'Assigned', value: 'assigned' }
          ]}
          disabled={loading}
        />
      </FlexBox>

      {groupedForms.length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No forms for you..."
        />
      ) : (
        groupedForms.map(group => {
          const isExpanded = expandedGroups[group.entityId];
          const forms = isExpanded ? group.forms : group.forms.slice(0, FORMS_PER_GROUP);
          const groupHasMore = !isExpanded && group.forms.length > FORMS_PER_GROUP;

          return (
            <FlexCard
              key={group.entityId}
              style={{ width: '100%' }}
              size="small"
              bordered={false}
            >
              <FlexBox gap={5}>
                <Title level={5} style={{ margin: 0 }}>
                  {group.entityName}
                </Title>
                <Button
                  type="link"
                  size="small"
                  icon={<ExpandOutlined />}
                  onClick={() => setSelectedRecordId(group.entityId)}
                />
                {group.error && (
                  <Text
                    type="warning"
                    style={{ marginLeft: 10, fontSize: '0.8em' }}
                  >
                    {group.error}
                  </Text>
                )}
              </FlexBox>
              <Steps
                direction="vertical"
                items={forms.map((form, index) => {
                  const version = group.versions[index];
                  return {
                    title: (
                      <FlexBox justifyStart wrap>
                        <Tag color="default" style={{ marginRight: 0 }}>
                          {form?.formType}
                        </Tag>
                        {version?.state && (
                          <Tag
                            color={getStateTagColor(version.state)}
                            bordered={false}
                            style={{ marginRight: 0 }}
                          >
                            {formStateDescription[version.state]?.shortLabel}
                          </Tag>
                        )}
                        <Link to={`/forms/${form.docId}`}>
                          <Text strong>{form.title}</Text>
                        </Link>
                        {form.formNumber && (
                          <Text type="secondary">#{form.formNumber}</Text>
                        )}
                      </FlexBox>
                    ),
                    description: (
                      <FlexBox noGrow justifyStart>
                        <Badge
                          dot
                          status={form.notificationStatus === 'unread' ? 'error' : 'default'}
                          onClick={() => toggleNotification(form.docId || '')}
                          style={{ cursor: 'pointer' }}
                        />
                        {form.meta?.lastModified && form.meta.lastModified instanceof Timestamp && (
                          <Text type="secondary">
                            {dayjs(form.meta.lastModified.toDate()).fromNow()}
                          </Text>
                        )}
                      </FlexBox>
                    ),
                    status: mapFormStateToStepStatus(version?.state)
                  };
                })}
              />
              {groupHasMore && (
                <Button
                  type="text"
                  size="small"
                  style={{
                    marginTop: -15,
                    marginLeft: 45,
                    marginBottom: 10,
                  }}
                  onClick={() => setExpandedGroups(prev => ({
                    ...prev,
                    [group.entityId]: !isExpanded
                  }))}
                >
                  {isExpanded ? 'Show Less' : `Show ${group.forms.length - FORMS_PER_GROUP} More...`}
                </Button>
              )}
            </FlexCard>
          );
        })
      )}

      <EntityRecordModal
        entityType={entityType}
        recordId={selectedRecordId}
        onClose={() => setSelectedRecordId(undefined)}
      />

      <Divider style={{ margin: 0 }} />
      <FlexBox justifyCenter noGrow>
        <Button
          type="link"
          onClick={loadMore}
          disabled={!hasMore || groupedForms.length === 0}
        >
          {hasMore ? 'Load More' : 'No more forms'}
        </Button>
      </FlexBox>
    </FlexCol>
  );
}; 