import { FC, useState, useContext, useCallback } from 'react';
import { Collapse, CollapseProps, Typography, Tag, Alert } from 'antd';
import { 
    Fields, 
    UIElement, 
    UIElementType, 
    AgentType,
} from '../../types/System.types';
import ControlUI from './ControlUI';
import { UserContext } from '../../providers/UserProvider';
import { Timestamp } from 'firebase/firestore';
import { useFormAndEntityContexts } from '../../use/useFormAndEntityContexts';
import FlexBox from '../atoms/FlexBox';

interface UnmappedFieldsUIProps {
  uiElement: UIElement;
}

const UnmappedFieldsUI: FC<UnmappedFieldsUIProps> = ({ uiElement }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = useContext(UserContext);
  const { 
    insideEntityContext,
    insideFormContext,
    data,
    formReadOnly,
    hasEntityAccess,
    entityLocked,
    debouncedSaveEntityRecord,
    selectedFormVersion
  } = useFormAndEntityContexts();

  const getDisplayedFields = (element: UIElement): string[] => {
    if (element.uiElementType === UIElementType.Field) {
      return [element.fieldId];
    } else if (element.uiElementType === UIElementType.EntityReference) {
      return [];
    } else if ('children' in element) {
      return element.children.flatMap(getDisplayedFields);
    }
    return [];
  };

  const displayedFields = getDisplayedFields(uiElement);
  const fields: Fields = data?.fields ?? {};
  const extraFields = Object.entries(fields).filter(
    ([fieldName]) => !displayedFields.includes(fieldName)
  );

  const handleFieldChange = useCallback((fieldName: string, value: any) => {
    const updateObject = {
      fields: { 
        [fieldName]: { 
          value: value,
          meta: {
            lastModified: Timestamp.now(),
            userId: user?.uid,
            agentType: AgentType.User,
          }
        } 
      } 
    };

    if (insideEntityContext) {
      debouncedSaveEntityRecord(updateObject);
    } else if (insideFormContext) {
      selectedFormVersion?.debouncedSet(updateObject);
    }
  }, [insideEntityContext, debouncedSaveEntityRecord, insideFormContext, selectedFormVersion, user?.uid]);

  const isReadOnly = insideEntityContext 
    ? !hasEntityAccess || entityLocked
    : formReadOnly;

  if (!insideEntityContext && !insideFormContext) {
    return <Alert message="Error: Neither entity nor form context is available." type="error" />;
  }

  if (extraFields.length === 0) {
    return null;
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      forceRender: false,
      label: (
        <FlexBox>
          <span>
            Additional Fields
          </span>
          <Tag style={{ margin: 0 }}>
            {extraFields.length} fields
          </Tag>
        </FlexBox>
      ),
      children: extraFields.map(([fieldName, field]) => (
        <FlexBox
          key={fieldName}
          column
          justifyStart
          alignStart
          gap={0}
          stretch
          style={{
            marginBottom: 10,
          }}
        >
          <Typography.Text>{fieldName}</Typography.Text>
          <ControlUI
            value={field?.value}
            field={field}
            uiField={{
              uiElementType: UIElementType.Field,
              fieldId: fieldName,
              fieldTypeId: 'undefined',
            }}
            readOnly={isReadOnly}
            onChange={(value: unknown) => handleFieldChange(fieldName, value)}
          />
        </FlexBox>
      ))
    }
  ];

  return (
    <Collapse
      style={{ 
        marginTop: 10,
        width: '100%',
      }}
      bordered={false}
      items={items}
      activeKey={isExpanded ? ['1'] : []}
      onChange={() => setIsExpanded(!isExpanded)}
      size='small'
      destroyInactivePanel
    />
  );
};

export default UnmappedFieldsUI;