import { FC } from 'react';
import {
    Table,
    Typography,
    List,
    Grid,
    Button,
} from 'antd';
import { 
    DownSquareOutlined,
    VerticalAlignTopOutlined 
} from '@ant-design/icons';
import { Timestamp } from 'firebase/firestore';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Form, FormType } from '../../types/System.types';
import FlexCard from '../atoms/FlexCard';
import { UseTableResult } from '../../use/useTable';
import FlexBox from '../atoms/FlexBox';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

interface FormsTableProps {
    tableProps: UseTableResult<Form>;
    formTypes?: FormType[];
}

const FormsTable: FC<FormsTableProps> = ({
    tableProps,
    formTypes = []
}) => {
    const navigate = useNavigate();
    const { md } = useBreakpoint();

    const columns: ColumnsType<Form> = [
        {
            title: 'Form Number',
            dataIndex: 'formNumber',
            key: 'formNumber',
            sorter: true,
            render: (formNumber?: string) => formNumber || '-'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            render: (title?: string) => title || '-'
        },
        {
            title: 'Form Type',
            dataIndex: 'formType',
            key: 'formType',
            render: (formTypeId?: string) => {
                if (!formTypeId) return '-';
                const formType = formTypes?.find(ft => ft.docId === formTypeId);
                return formType?.description?.shortLabel || formTypeId;
            },
        },
        {
            title: 'Created',
            dataIndex: ['meta', 'created'],
            key: 'created',
            sorter: true,
            render: (timestamp?: Timestamp) => {
                if (!timestamp) return '-';
                if (timestamp instanceof Timestamp) {
                    return timestamp.toDate().toLocaleString();
                }
                return 'Invalid Date';
            },
        },
        {
            title: 'Last Updated',
            dataIndex: ['meta', 'lastModified'],
            key: 'lastModified',
            sorter: true,
            render: (timestamp?: Timestamp) => {
                if (!timestamp) return '-';
                if (timestamp instanceof Timestamp) {
                    return timestamp.toDate().toLocaleString();
                }
                return 'Invalid Date';
            },
        },
    ];

    const {
        tableData,
        isLoading,
        error,
        loadMore,
        returnToStart,
        hasMore,
        isFirstPage,
        setOrderBy
    } = tableProps;

    const handleTableChange: TableProps<Form>['onChange'] = (_, __, sorter) => {
        if (sorter && !Array.isArray(sorter) && sorter.order) {
            const direction = sorter.order === 'ascend' ? 'asc' : 'desc';
            const field = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field as string;
            setOrderBy([{ field, direction }]);
        }
    };

    if (error) {
        return <Text type="danger">Error: {error.message}</Text>;
    }

    const tableControls = (
        <FlexBox noGrow>
            <Button
                disabled={!hasMore}
                type='link'
                onClick={loadMore}
                loading={isLoading}
                icon={<DownSquareOutlined />}
            >
                Load More
            </Button>
            {!isFirstPage && (
                <Button
                    onClick={returnToStart}
                    type="link"
                    icon={<VerticalAlignTopOutlined />}
                >
                    Return to Start
                </Button>
            )}
        </FlexBox>
    );

    if (!md) {
        return (
            <>
                <List
                    dataSource={tableData}
                    loading={isLoading}
                    style={{ width: '100%' }}
                    renderItem={(item: Form) => (
                        <FlexCard
                            key={item.docId}
                            alignStart
                            hoverable
                            onClick={() => navigate(`/forms/${item.docId}`)}
                            style={{
                                marginBottom: 16,
                                cursor: 'pointer',
                                width: '100%'
                            }}
                        >
                            <Title level={5} style={{ margin: 0 }}>
                                {item.title}
                            </Title>
                            <Text>Form Number: {item.formNumber}</Text>
                            <Text>Form Type: {
                                formTypes?.find(ft => ft.docId === item.formType)?.description?.shortLabel
                                || item.formType
                            }</Text>
                            <Text>
                                Created: {
                                    item.meta?.created instanceof Timestamp
                                        ? item.meta.created.toDate().toLocaleString()
                                        : 'Invalid Date'
                                }
                            </Text>
                        </FlexCard>
                    )}
                />
                {tableControls}
            </>
        );
    }

    return (
        <>
            <Table
                style={{ width: '100%' }}
                dataSource={tableData}
                columns={columns}
                loading={isLoading}
                pagination={false}
                size='small'
                onChange={handleTableChange}
                onRow={(record) => ({
                    onClick: () => navigate(`/forms/${record.docId}`),
                    style: { cursor: 'pointer' },
                })}
                scroll={{ x: 'max-content' }}
                showSorterTooltip={false}
            />
            {tableControls}
        </>
    );
};

export default FormsTable;