import { FC, ReactNode, useContext } from 'react';
import { FormContext, FormContextType } from '../artifacts/FormProvider';
import { FormVersionContext, FormVersionContextType } from '../artifacts/FormVersionProvider';
import { LayoutEditorContext } from './LayoutEditorProvider';
import { Form, FormVersion, FormType, EditorUILayout } from '../../types/System.types';

/**
 * Creates a mock form context for preview purposes.
 * This context simulates a form environment with read/write permissions
 * and basic form structure for previewing layouts in the editor.
 * 
 * @param {EditorUILayout} editorLayout - The current layout being edited
 * @returns {FormContextType} A mock form context for preview
 */
const createPreviewFormContext = (editorLayout: EditorUILayout): FormContextType => ({
    selectedForm: {
        data: {
            title: 'Preview Form',
            formType: 'preview',
            currentVersionId: 'preview',
            organization: 'Preview',
            userRoles: {}
        } as Form,
        loading: false,
        error: null,
        updateData: () => Promise.resolve(),
        debouncedSet: () => undefined,
        set: () => Promise.resolve(),
        clear: () => Promise.resolve(),
    },
    formTypeOfSelectedForm: {
        data: {
            defaultUILayout: editorLayout,
            docId: 'preview',
            possibleStates: {
                draft: { allowedNextStates: ['draft'] }
            }
        } as FormType,
        loading: false,
        error: null,
        updateData: () => Promise.resolve(),
        debouncedSet: () => undefined,
        set: () => Promise.resolve(),
        clear: () => Promise.resolve(),
    },
    selectedUILayout: {
        data: editorLayout,
        loading: false,
        error: null,
        updateData: () => Promise.resolve(),
        debouncedSet: () => undefined,
        set: () => Promise.resolve(),
        clear: () => Promise.resolve(),
    },
    uiLayouts: {
        docs: [],
        data: [],
        error: null,
        loading: false,
        refresh: () => Promise.resolve(),
        setFilters: () => undefined,
        setOrderBy: () => undefined,
        setLimit: () => undefined,
        setStartAt: () => undefined,
        filters: undefined,
        orderBy: undefined,
        limit: undefined,
        startAt: undefined,
        clear: () => undefined,
    },
    formTypes: {
        docs: [],
        data: [],
        error: null,
        loading: false,
        refresh: () => Promise.resolve(),
        setFilters: () => undefined,
        setOrderBy: () => undefined,
        setLimit: () => undefined,
        setStartAt: () => undefined,
        filters: undefined,
        orderBy: undefined,
        limit: undefined,
        startAt: undefined,
        clear: () => undefined,
    },
    selectForm: () => undefined,
    selectUILayout: () => undefined,
    insideFormContext: true,
    visibilityMode: 'internal',
    setVisibilityMode: () => undefined,
    navigateToForm: () => undefined,
});

/**
 * Creates a mock form version context for preview purposes.
 * This context simulates a form version environment with read/write permissions
 * for previewing layouts in the editor.
 * 
 * @returns {FormVersionContextType} A mock form version context for preview
 */
const createPreviewVersionContext = (): FormVersionContextType => ({
    selectedFormVersion: {
        data: {
            state: 'draft',
            fields: {},
            entityReferences: {}
        } as FormVersion,
        loading: false,
        error: null,
        updateData: () => Promise.resolve(),
        debouncedSet: () => undefined,
        set: () => Promise.resolve(),
        clear: () => Promise.resolve(),
    },
    formVersions: {
        docs: [],
        data: [],
        error: null,
        loading: false,
        refresh: () => Promise.resolve(),
        setFilters: () => undefined,
        setOrderBy: () => undefined,
        setLimit: () => undefined,
        setStartAt: () => undefined,
        filters: undefined,
        orderBy: undefined,
        limit: undefined,
        startAt: undefined,
        clear: () => undefined,
    },
    selectFormVersion: () => undefined,
    formVersionAccess: {
        canRead: true,
        canWrite: true,
        loading: false,
        error: null
    },
    navigateToVersion: () => undefined,
});

/**
 * PreviewFormProvider creates a simulated form and form version context for previewing
 * form layouts in the editor. It wraps the editor's current layout
 * in mock contexts that allow for realistic preview rendering.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - Child components to render within the preview context
 * @returns {JSX.Element} The provider component with preview contexts
 * @throws {Error} If used outside of LayoutEditorProvider context
 */
export const PreviewFormProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const editor = useContext(LayoutEditorContext);
    
    if (!editor) {
        throw new Error('PreviewFormProvider must be used within a LayoutEditorProvider');
    }

    const formValue = createPreviewFormContext(editor.layout);
    const versionValue = createPreviewVersionContext();

    return (
        <FormContext.Provider value={formValue}>
            <FormVersionContext.Provider value={versionValue}>
                {children}
            </FormVersionContext.Provider>
        </FormContext.Provider>
    );
}; 