import { useBoundCollection } from './data/useBoundCollection';
import { useBoundDoc } from './data/useBoundDoc';
import { useSet } from './data/useSet';
import { useAdd } from './data/useAdd';
import { useRemove } from './data/useRemove';
import { FormType, Description, TimeType, UIElementType } from '../types/System.types';
import { FormStateId } from '../types/System.Parameters.types';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';

interface UpdateStateDescriptionParams {
    formTypeId: string;
    stateId: FormStateId;
    description: Partial<Description>;
}

interface UpdateAllowedNextStatesParams {
    formTypeId: string;
    stateId: FormStateId;
    allowedNextStates: FormStateId[];
}

interface CreateFormTypeParams {
    userId: string;
}

export const useFormTypes = () => {
    const [selectedFormTypeId, setSelectedFormTypeId] = useState<string | undefined>(undefined);

    const formTypes = useBoundCollection<FormType>({
        path: 'formTypes',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
    });

    const selectedFormType = useBoundDoc<FormType>({
        path: 'formTypes',
        docId: selectedFormTypeId,
        enabled: !!selectedFormTypeId
    });

    const selectFormType = (formTypeId: string) => setSelectedFormTypeId(formTypeId);
    const clearFormTypeSelection = () => {
        setSelectedFormTypeId(undefined);
        selectedFormType.clear();
    };

    const { set } = useSet();
    const { add } = useAdd();
    const { remove } = useRemove();

    const updateStateDescription = async ({
        formTypeId,
        stateId,
        description
    }: UpdateStateDescriptionParams) => {
        const formType = formTypes.data?.find(ft => ft.docId === formTypeId);
        const currentState = formType?.possibleStates?.[stateId];
        
        return set<FormType>('formTypes', formTypeId, {
            possibleStates: {
                [stateId]: {
                    allowedNextStates: currentState?.allowedNextStates || []
                }
            }
        });
    };

    const updateAllowedNextStates = async ({
        formTypeId,
        stateId,
        allowedNextStates
    }: UpdateAllowedNextStatesParams) => {
        return set<FormType>('formTypes', formTypeId, {
            possibleStates: {
                [stateId]: {
                    allowedNextStates
                }
            }
        });
    };

    const updateAllowedNextForms = async (formTypeId: string, allowedNextForms: FormType["docId"][]) => {
        return set<FormType>('formTypes', formTypeId, {
            allowedNextForms
        });
    };

    const createFormType = async ({ userId }: CreateFormTypeParams) => {
        const now = Timestamp.now() as TimeType;
        const newFormType: Omit<FormType, 'docId'> = {
            defaultUILayout: {
                structure: {
                    uiElementType: UIElementType.Column,
                    children: []
                }
            },
            description: {
                shortLabel: 'New',
                shortDescription: '',
                longLabel: '',
                longDescription: ''
            },
            meta: {
                created: now,
                lastModified: now,
                userId,
                version: '1.0'
            },
            possibleStates: {
                draft: {
                    allowedNextStates: ['draft']
                }
            },
            allowedNextForms: []
        };

        return add('formTypes', newFormType);
    };

    const deleteFormType = async (formTypeId: string) => {
        try {
            await remove('formTypes', formTypeId);
            return true;
        } catch (error) {
            console.error('Error deleting form type:', error);
            return false;
        }
    };

    const addStateToFormType = async (formTypeId: string, stateName: string) => {
        return set<FormType>('formTypes', formTypeId, {
            possibleStates: {
                [stateName]: {
                    allowedNextStates: []
                }
            }
        });
    };

    const removeStateFromFormType = async (formTypeId: string, stateId: string) => {
        const formType = formTypes.data?.find(ft => ft.docId === formTypeId);
        if (!formType?.possibleStates) return;

        if (Object.keys(formType.possibleStates).length <= 1) {
            throw new Error('Cannot delete the last state');
        }

        const isReferenced = Object.entries(formType.possibleStates).some(([otherStateId, state]) => 
            otherStateId !== stateId && state.allowedNextStates?.includes(stateId as FormStateId)
        );

        if (isReferenced) {
            throw new Error('Cannot delete state that is referenced by other states');
        }

        const { [stateId]: _, ...remainingStates } = formType.possibleStates;
        
        return set<FormType>('formTypes', formTypeId, {
            possibleStates: remainingStates
        });
    };

    return {
        ...formTypes,
        selectedFormTypeId,
        selectedFormType,
        selectFormType,
        clearFormTypeSelection,
        updateStateDescription,
        updateAllowedNextStates,
        updateAllowedNextForms,
        createFormType,
        deleteFormType,
        addStateToFormType,
        removeStateFromFormType
    };
}; 