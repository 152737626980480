import { FC } from 'react';
import { Modal, Radio, Space, Typography, Divider } from 'antd';
import { FieldViewVisibility, FieldViewVisibilityDescription } from '../../types/System.Parameters.types';

const { Text } = Typography;

interface FormExportModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  exportVisibilityMode: FieldViewVisibility;
  setExportVisibilityMode: (mode: FieldViewVisibility) => void;
}

/** Modal for configuring PDF export settings */
const FormExportModal: FC<FormExportModalProps> = ({
  open,
  onCancel,
  onConfirm,
  exportVisibilityMode,
  setExportVisibilityMode
}) => {
  return (
    <Modal
      title="Export PDF"
      open={open}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Export"
      cancelText="Cancel"
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text>
          Select which view mode to use for the PDF export:
        </Text>
        <Radio.Group
          value={exportVisibilityMode}
          onChange={(e) => setExportVisibilityMode(e.target.value)}
          style={{ width: '100%' }}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Radio value={'external'}>
              <Text strong>External View</Text>
              <br />
              <Text type="secondary"> {FieldViewVisibilityDescription.external.longDescription}</Text>
            </Radio>
            <Radio value={'contractor'}>
              <Text strong>Contractor View</Text>
              <br />
              <Text type="secondary"> {FieldViewVisibilityDescription.contractor.longDescription}</Text>
            </Radio>
            <Divider style={{
              marginTop: 12,
              paddingTop: 12
            }} />
            <Radio value={'internal'}>
              <Text strong>Internal View</Text>
              <br />
              <Text type="secondary"> {FieldViewVisibilityDescription.internal.longDescription}</Text>
              <br />
              <Text type="warning"> {FieldViewVisibilityDescription.internal.warning}</Text>
            </Radio>
          </Space>
        </Radio.Group>
      </Space>
    </Modal>
  );
};

export default FormExportModal; 