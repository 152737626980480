import { FC, ReactNode, createContext, useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useBoundCollection } from '../../use/data/useBoundCollection';
import { useBoundDoc } from '../../use/data/useBoundDoc';
import { FormVersion } from '../../types/System.types';
import { useEvaluateFormAccess } from '../../use/data/useEvaluateFormAccess';
import { useNavigate, useParams } from 'react-router-dom';
import { FormContext } from './FormProvider';

/**
 * @exports FormVersionContext
 * Provides form version–specific data including the selected version, the collection of versions, and access evaluation.
 */
export interface FormVersionContextType {
    selectedFormVersion: ReturnType<typeof useBoundDoc<FormVersion>>;
    formVersions: ReturnType<typeof useBoundCollection<FormVersion>>;
    selectFormVersion: (formVersionId: string | undefined) => void;
    formVersionAccess: ReturnType<typeof useEvaluateFormAccess>;
    navigateToVersion: (formId: string, versionId: string) => void;
}

export const FormVersionContext = createContext<FormVersionContextType>({} as FormVersionContextType);

/**
 * @exports BoundFormVersionProvider
 * Provides versioning functionality by subscribing to the selected form's versions.
 */
export const FormVersionProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { versionId } = useParams<{ versionId?: string }>();
    const { selectedForm } = useContext(FormContext);
    const formId = selectedForm.data?.docId ?? '';

    const [selectedFormVersionId, selectFormVersion] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const formVersions = useBoundCollection<FormVersion>({
        path: `forms/${formId}/formVersions`,
        enabled: !!formId,
    });

    const selectedFormVersion = useBoundDoc<FormVersion>({
        path: `forms/${formId}/formVersions`,
        docId: selectedFormVersionId,
        enabled: !!formId && !!selectedFormVersionId,
    });

    const formVersionAccess = useEvaluateFormAccess({
        formId,
        versionId: selectedFormVersionId,
        enabled: true,
    });

    useEffect(() => {
        if (versionId) {
            selectFormVersion(versionId);
        } else if (selectedForm.data?.currentVersionId) {
            selectFormVersion(selectedForm.data.currentVersionId);
        }
    }, [versionId, selectedForm.data?.currentVersionId, selectFormVersion]);

    const navigateToVersion = useCallback((formId: string, versionId: string) => {
        navigate(`/forms/${formId}/versions/${versionId}`);
        selectFormVersion(versionId);
    }, [navigate]);

    const value = useMemo(() => ({
        selectedFormVersion,
        formVersions,
        selectFormVersion,
        formVersionAccess,
        navigateToVersion,
    }), [
        selectedFormVersion,
        formVersions,
        selectFormVersion,
        formVersionAccess,
        navigateToVersion,
    ]);

    return (
        <FormVersionContext.Provider value={value}>
            {children}
        </FormVersionContext.Provider>
    );
};