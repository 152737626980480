import { FC, memo, useMemo } from 'react';
import { UIElement, UIElementType } from '../../types/System.types';
import FieldUI from './FieldUI';
import ContainerUI from './ContainerUI';
import MetaFieldUI from './MetaFieldUI';
import EntityUIContext from './EntityUIContext';

interface LayoutUIProps {
  uiElement: UIElement;
  parentLocked?: boolean;
}

const LayoutUI: FC<LayoutUIProps> = memo(({
  uiElement,
  parentLocked = false
}) => {
  const content = useMemo(() => {
    switch (uiElement.uiElementType) {
      case UIElementType.Field:
        return <FieldUI uiField={uiElement} />;
      case UIElementType.EntityReference:
        return <EntityUIContext 
          entityId={uiElement?.entityId} 
          uiElement={uiElement} 
        />;
      case UIElementType.MetaField:
        return <MetaFieldUI uiMetaField={uiElement} />;
      case UIElementType.Grid:
      case UIElementType.Row:
      case UIElementType.Column:
        return <ContainerUI
          container={uiElement}
          parentLocked={parentLocked}
        />;
      default:
        console.warn(`Unknown UI element type: ${(uiElement as any).uiElementType}`);
        return null;
    }
  }, [uiElement, parentLocked]);

  return content;
});

LayoutUI.displayName = 'LayoutUI';

export default LayoutUI;