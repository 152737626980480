import { FC, useContext } from 'react';
import { Button, Space, Tooltip, theme, Switch } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { UIElementType, UIField, UIEntityReference } from '../../types/System.types';
import { LayoutEditorContext } from '../../providers/editor/LayoutEditorProvider';
import { EditorUIElement, EditorUIContainer } from '../../types/System.types';
import { AddElementMenu } from './element/AddElementMenu';

const { useToken } = theme;

interface LayoutEditorControlsProps {
    element: EditorUIElement;
    parentElement: EditorUIElement | null;
}

/**
 * Controls component for the layout editor that provides element manipulation options
 * Displays add and remove controls for container elements and remove controls for fields
 * @component
 * @param {LayoutEditorControlsProps} props - Props for the LayoutEditorControls component
 * @returns {JSX.Element} The rendered LayoutEditorControls component
 */
export const LayoutEditorControls: FC<LayoutEditorControlsProps> = ({
    element,
    parentElement,
}) => {
    const { token } = useToken();
    const editor = useContext(LayoutEditorContext);
    if (!editor) throw new Error('Editor must be used within LayoutEditorProvider');

    const { removeElement } = editor;
    const isContainer = [UIElementType.Grid, UIElementType.Row, UIElementType.Column].includes(element.uiElementType);
    const isField = element.uiElementType === UIElementType.Field;
    const isEntityReference = element.uiElementType === UIElementType.EntityReference;
    const canShowRequiredToggle = isField || isEntityReference;

    const handleRequiredChange = (checked: boolean) => {
        if (!canShowRequiredToggle) return;
        editor.updateRequiredState(element.dndId, checked);
    };

    return (
        <Space
            style={{
                position: 'absolute',
                bottom: '50%',
                left: '50%',
                transform: 'translate(-50%, 50%)',
                padding: '2px 5px',
                background: token.colorBgContainer,
                borderRadius: token.borderRadiusLG,
                boxShadow: token.boxShadowTertiary,
                zIndex: 1
            }}
        >
            {isContainer && (
                <AddElementMenu
                    size="small"
                    variant='borderless'
                    style={{ width: 120 }}
                />
            )}
            {canShowRequiredToggle && (
                <Tooltip title="Mark as required">
                    <Switch
                        checked={(element as UIField | UIEntityReference).required}
                        onChange={handleRequiredChange}
                        checkedChildren="Required"
                        unCheckedChildren="Optional"
                    />
                </Tooltip>
            )}
            <Tooltip title={`Remove ${isContainer ? 'Container' : (isField ? 'Field' : isEntityReference ? 'Entity Record' : 'Meta Field')}`}>
                <Button
                    type="text"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (parentElement) {
                            removeElement(parentElement as EditorUIContainer, element.dndId);
                        }
                    }}
                />
            </Tooltip>
        </Space>
    );
};

export default LayoutEditorControls; 