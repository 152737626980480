import { FC, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Skeleton,
    Typography,
    Grid,
    Cascader,
} from 'antd';
import { theme } from 'antd';

import FlexPage from '../components/atoms/FlexPage';
import Navb from '../components/Navb';
import { EntityContext, EntityProvider } from '../providers/artifacts/EntityProvider';
import { EntityRecordContext, EntityRecordProvider } from '../providers/artifacts/EntityRecordProvider';
import { Entity as EntityType } from '../types/System.types';
import Entity from '../components/Entity';
import { SearchWrapper } from '../components/SearchWrapper';
import FlexBox from '../components/atoms/FlexBox';
import FlexContent from '../components/atoms/FlexContent';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW } from '../types/System.Parameters.types';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const EntitiesPageContent: FC = () => {
    const navigate = useNavigate();
    const { entityId, recordId } = useParams<{ entityId?: string; recordId?: string }>();
    const screens = useBreakpoint();
    const { token } = theme.useToken();

    const {
        entities,
        selectedEntity,
        selectEntity,
        clearEntity,
    } = useContext(EntityContext);

    const {
        selectEntityRecord,
        clearEntityRecord,
    } = useContext(EntityRecordContext);

    useEffect(() => {
        // Clear the record whenever the selected entity changes.
        clearEntityRecord();
        if (entityId !== undefined) {
            selectEntity(entityId);
        } else {
            clearEntity();
        }
    }, [entityId, selectEntity, clearEntity, clearEntityRecord]);

    useEffect(() => {
        clearEntityRecord();
        if (recordId !== undefined) {
            selectEntityRecord(recordId);
        }
    }, [recordId, selectEntityRecord, clearEntityRecord]);

    const handleEntitySelect = async (entityId: string | undefined) => {
        if (!entityId) {
            navigate('/entities');
            return;
        }
        await selectEntity(entityId);
        navigate(`/entities/${entityId}`);
    };

    if (!entities) {
        return <Skeleton active />;
    }

    const breakpoint = !screens.md;
    
    return (
        <FlexPage>
            <Navb />
            <SearchWrapper
                index="entityRecord"
                filters={`docIdParent:${selectedEntity?.docId}`}
            >
                <FlexContent>
                    <FlexBox
                        noGrow
                        alignStart
                        justifyStart
                        stretch
                        column={breakpoint}
                    >
                        {breakpoint? (
                            <Cascader
                                options={entities.map((entity: EntityType) => ({
                                    label: entity?.description?.shortLabel || entity?.description?.shortDescription || entity?.docId,
                                    value: entity.docId,
                                }))}
                                onChange={([value]) => handleEntitySelect(value as string)}
                                value={selectedEntity?.docId ? [selectedEntity.docId] : []}
                                style={{
                                    minWidth: 300,
                                    color: token.colorText,
                                }}
                            />
                        ) : (
                            <Cascader.Panel
                                options={entities.map((entity: EntityType) => ({
                                    label: entity?.description?.shortLabel || entity?.description?.shortDescription || entity?.docId,
                                    value: entity.docId,
                                }))}
                                onChange={([value]) => handleEntitySelect(value as string)}
                                value={selectedEntity?.docId ? [selectedEntity.docId] : []}
                                style={{
                                    minWidth: 300,
                                    color: token.colorText,
                                }}
                            />
                        )}
                        <FlexBox
                            stretch
                            alignStart
                            style={{
                                width: '100%',
                                maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW,
                            }}
                        >
                            {selectedEntity ?
                                <Entity key={selectedEntity.docId} />
                                :
                                <Text>
                                    Select a record type.
                                </Text>
                            }
                        </FlexBox>
                    </FlexBox>
                </FlexContent>
            </SearchWrapper>
        </FlexPage>
    );
};

const EntitiesPage: FC = () => {
    const { entityId } = useParams<{ entityId?: string }>();
    
    return (
        <EntityProvider initialEntityId={entityId}>
            <EntityRecordProvider>
                <EntitiesPageContent />
            </EntityRecordProvider>
        </EntityProvider>
    );
};

export default EntitiesPage;