import { FC, useContext, useState } from 'react';
import { Button } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { FormVersionContext } from '../../providers/artifacts/FormVersionProvider';
import FlexCard from '../atoms/FlexCard';
import FlexBox from '../atoms/FlexBox';
import FormAccessModal from '../modals/FormAccessModal';
import FormAssignControl from './FormAssignControl';
import FormSubscriberControl from './form/FormSubscriberControl';
import FormVisibilityModeSelect from './form/FormVisibilityModeSelect';
import FormVersionSelect from './form/FormVersionSelect';
import FormWorkflowSelect from './form/FormWorkflowSelect';
import { FormContext } from '../../providers/artifacts/FormProvider';

interface FormControlProps {
  contentRef: React.RefObject<HTMLDivElement>;
}

const FormControl: FC<FormControlProps> = ({ contentRef }: FormControlProps) => {
  const {
    selectedForm,
  } = useContext(FormContext);
  const {
    formVersionAccess,
  } = useContext(FormVersionContext);

  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);

  return (
    <FlexCard
      row
      wrap
      alignCenter
      style={{
        minWidth: '100%',
        marginBottom: 10,
      }}
    >
      <FlexBox noGrow wrap>
        <FormVersionSelect />
        <Button
          type='text'
          icon={
            formVersionAccess.canWrite ? <UnlockOutlined /> :
            formVersionAccess?.canRead ? <UnlockOutlined /> :
            <LockOutlined />
          }
          size='small'
          onClick={() => setIsAccessModalOpen(true)}
        >
          {formVersionAccess?.canWrite ? 'Full Access' : 'Read Only'}
        </Button>
      </FlexBox>
      <FlexBox
        stretch
        justifyEnd
        gap={5}
        wrap
        noGrow
      >
        <FormVisibilityModeSelect />
        <FormAssignControl />
        <FormSubscriberControl />
        <FormWorkflowSelect
          formId={selectedForm.data?.docId || ''}
          contentRef={contentRef}
        />
      </FlexBox>
      <FormAccessModal
        formId={selectedForm.data?.docId || ''}
        open={isAccessModalOpen}
        onClose={() => setIsAccessModalOpen(false)}
      />
    </FlexCard>
  );
};

export default FormControl;
