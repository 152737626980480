import { useContext, useState, useCallback } from 'react';
import { FormContext } from '../providers/artifacts/FormProvider';
import { FormVersionContext } from '../providers/artifacts/FormVersionProvider';
import { EntityContext } from '../providers/artifacts/EntityProvider';
import { EntityRecordContext } from '../providers/artifacts/EntityRecordProvider';
import { UserContext } from '../providers/UserProvider';
import { Entity, EntityRecord, FormVersion } from '../types/System.types';
import { UseBoundDoc } from './data/useBoundDoc';

interface UseFormAndEntityContextsReturn {
  /** Indicates if component is within a form or entity context */
  hasContext: boolean;
  /** Indicates if the current form is in read-only mode */
  formReadOnly: boolean;
  /** Indicates if user has access to the current entity */
  hasEntityAccess: boolean;
  /** Currently selected entity or null if none selected */
  selectedEntity: Entity | null;
  /** Currently selected entity record or null if none selected */
  selectedEntityRecord: EntityRecord | null;
  /** Indicates if component is within an entity context */
  insideEntityContext: boolean;
  /** Indicates if component is within a form context */
  insideFormContext: boolean;
  /** Creates a new entity record and returns its ID */
  createNewEntityRecord: () => Promise<string>;
  /** Selects an entity record by ID */
  selectEntityRecord: (value: string | undefined) => Promise<void>;
  /** Deletes an entity record by ID */
  deleteRecord: (recordId: string) => Promise<void>;
  /** Saves a reference to an entity */
  saveEntityReference: (value: string | undefined) => Promise<void>;
  /** Handles saving data to the database */
  handleDBSave: (value: any) => void;
  /** Currently selected form version with bound document functionality */
  selectedFormVersion?: UseBoundDoc<FormVersion>;
  /** Sets the entity locked state */
  setEntityLocked: (value: boolean) => void;
  /** Debounced function to save entity record changes */
  debouncedSaveEntityRecord: (value: Partial<EntityRecord>) => void;
  /** Indicates if the entity is locked for editing */
  entityLocked: boolean;
  /** Indicates if a save operation is in progress */
  isSaving: boolean;
  /** Subscribes to changes in an entity record */
  subscribeToEntityRecord: (entityId: string, recordId: string) => Promise<void>;
  /** Unsubscribes from changes in an entity record */
  unsubscribeFromEntityRecord: (entityId: string, recordId: string) => Promise<void>;
  /** Indicates if the entity is locked in the current context */
  entityLockedInContext: boolean;
  /** Indicates if record selection is locked */
  recordSelectionLocked: boolean;
  /**
   * Combined data from the current context.
   * If inside an entity context, returns the selected entity record;
   * if inside a form context, returns the form version data;
   * otherwise, returns null.
   */
  data: EntityRecord | FormVersion | null;
}

/**
 * Hook to manage form and entity contexts, providing access to form versions, entity records,
 * and related operations. Handles state management, CRUD operations, and context-aware data access
 * for both form and entity contexts.
 * @returns {UseFormAndEntityContextsReturn} Object containing context states and operations
 */
export const useFormAndEntityContexts = (): UseFormAndEntityContextsReturn => {
  const [isSaving, setIsSaving] = useState(false);
  const formContext = useContext(FormContext);
  const formVersionContext = useContext(FormVersionContext);
  const entityContext = useContext(EntityContext);
  const entityRecordContext = useContext(EntityRecordContext);
  const parentRecordContext = useContext(EntityRecordContext);
  const { subscribeToEntityRecord, unsubscribeFromEntityRecord } = useContext(UserContext);

  const insideFormContext = formContext?.insideFormContext ?? false;
  const formVersionAccess = formVersionContext?.formVersionAccess;
  const formReadOnly = !formVersionAccess?.canWrite;
  const selectedFormVersion = formVersionContext?.selectedFormVersion;

  const insideEntityContext = entityContext?.insideEntityContext ?? false;
  const hasEntityAccess = entityContext?.hasEntityAccess;
  const selectedEntity = entityContext?.selectedEntity;
  const setEntityLocked = entityContext?.setEntityLocked ?? (() => {});
  const entityLocked = entityContext?.entityLocked ?? false;
  const parentEntityContext = entityContext?.parentEntityContext;

  const selectedEntityRecord = entityRecordContext?.selectedEntityRecord;
  const createNewEntityRecord = entityRecordContext?.createNewRecord ?? (() => Promise.resolve(''));
  const selectEntityRecord = entityRecordContext?.selectEntityRecord ?? (() => Promise.resolve());
  const debouncedSaveEntityRecord = entityRecordContext?.debouncedSetRecord;
  const deleteRecord = entityRecordContext?.deleteRecord ?? (() => Promise.resolve());

  const entityLockedInContext = parentEntityContext?.entityLocked || formReadOnly;
  const recordSelectionLocked = insideFormContext ? entityLockedInContext : false;

  const saveEntityReference = useCallback(async (value: string | undefined) => {
    try {
      setIsSaving(true);

      if (insideEntityContext && parentEntityContext && selectedEntity?.docId) {
        parentRecordContext?.debouncedSetRecord({
          entityReferences: {
            ...parentRecordContext?.selectedEntityRecord?.entityReferences,
            [selectedEntity.docId]: value || ''
          }
        });
      } else if (insideFormContext && selectedFormVersion && selectedEntity?.docId) {
        selectedFormVersion.debouncedSet({
          entityReferences: {
            ...selectedFormVersion.data?.entityReferences,
            [selectedEntity.docId]: value || ''
          }
        });
      }

      setTimeout(() => setIsSaving(false), 350);
    } catch (error) {
      console.error('Save entity reference failed:', error);
      setIsSaving(false);
    }
  }, [insideEntityContext, parentEntityContext, selectedEntity?.docId, insideFormContext, selectedFormVersion, parentRecordContext]);

  const handleDBSave = (value: any) => {
    if (insideEntityContext && entityRecordContext?.setRecord) {
      entityRecordContext.setRecord({
        fields: value,
      });
    } else if (insideFormContext && formVersionAccess?.canWrite && selectedFormVersion?.updateData) {
      selectedFormVersion.updateData({
        fields: value,
      });
    }
  };

  const data = insideEntityContext
    ? selectedEntityRecord
    : insideFormContext
      ? selectedFormVersion?.data ?? null
      : null;

  return {
    hasContext: insideEntityContext || insideFormContext,
    formReadOnly,
    hasEntityAccess,
    selectedEntity,
    selectedEntityRecord,
    insideEntityContext,
    insideFormContext,
    createNewEntityRecord,
    selectEntityRecord,
    deleteRecord,
    saveEntityReference,
    handleDBSave,
    selectedFormVersion,
    setEntityLocked,
    debouncedSaveEntityRecord,
    entityLocked,
    isSaving,
    subscribeToEntityRecord,
    unsubscribeFromEntityRecord,
    entityLockedInContext,
    recordSelectionLocked,
    data
  };
}; 