import { FC, useState } from 'react';
import { Typography, Button, Modal } from 'antd';
import { ExpandOutlined, LoadingOutlined } from '@ant-design/icons';

import EntityRecordSelect from '../atoms/EntityRecordSelect';
import FlexBox from '../atoms/FlexBox';
import FlexCol from '../atoms/FlexCol';
import Entity from '../Entity';
import { useFormAndEntityContexts } from '../../use/useFormAndEntityContexts';
import { UIElement } from '../../types/System.types';

const { Text } = Typography;

interface EntityUIProps {
  uiElement: UIElement;
}

const EntityUI: FC<EntityUIProps> = ({ uiElement }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    selectedEntity: entity,
    selectedEntityRecord: entityRecord,
    insideEntityContext,
    createNewEntityRecord,
    selectEntityRecord,
    setEntityLocked,
    hasEntityAccess,
    saveEntityReference,
    isSaving,
    entityLockedInContext,
    data,
  } = useFormAndEntityContexts();

  if (!entity || !insideEntityContext) return null;

  const handleAddNewRecord = async () => {
    try {
      const newRecordId = await createNewEntityRecord();
      setEntityLocked(false);
      await selectEntityRecord(newRecordId);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error creating new record:', error);
    }
  };

  const entityReference = data && 'entityReferences' in data && entity?.docId ? 
                  data.entityReferences[entity.docId] : 
                  undefined

  return (
    <FlexCol
      gap={0}
      noGrow
    >
      <Text>
        {entity?.description?.shortLabel || entity?.description?.shortDescription || entity?.docId}
        {isSaving && (
          <Typography.Text type='warning'>
            <LoadingOutlined />
          </Typography.Text>
        )}
        {uiElement?.required && (
          <Text type='secondary'>
            *
          </Text>
        )}
      </Text>
      <FlexBox justifyStart gap={5}>
        <EntityRecordSelect 
          entityId={entity.docId}
          recordId={entityRecord?.docId}
          onChange={async (value) => {
            await selectEntityRecord(value);
            await saveEntityReference(value);
          }}
          onAddRecord={handleAddNewRecord}
          disabled={entityLockedInContext}
          disableAddButton={!hasEntityAccess}
        />
        <Button
          type="link"
          size="small"
          icon={<ExpandOutlined />}
          onClick={() => setIsModalOpen(true)}
        />
      </FlexBox>
      <Modal
        title="Record Details"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={800}
        footer={[
          <Button 
            key="done"
            type="primary"
            onClick={() => setIsModalOpen(false)}
          >
            Done
          </Button>
        ]}
      >
        <Entity />
      </Modal>
    </FlexCol>
  );
};

export default EntityUI;