import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
    Menu, 
    Button, 
    theme, 
    Dropdown, 
    Grid,
    Badge
} from 'antd';
import type { MenuProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import Icon from './atoms/Icon';
import FlexBox from './atoms/FlexBox';
import Search from './atoms/Search';
import { ThemeContext } from '../providers/ThemeProvider';
import { UserContext } from '../providers/UserProvider';

export default function Navb() {
    const { token } = theme.useToken();
    const { useBreakpoint } = Grid;
    const { sm } = useBreakpoint();
    const { darkMode } = useContext(ThemeContext);
    const { userGroups, unreadNotifications } = useContext(UserContext);

    const { pathname } = useLocation();

    const selectedKey = pathname.split('/')[1].trim() || 'projects';

    const x = sm ? 20 : 10;

    const menuItems: MenuProps['items'] = [
        {
            key: 'home',
            label: <Link to='/'>Home</Link>,
        },
        {
            key: 'forms',
            label: <Link to='/forms'>Forms</Link>,
        },
        {
            key: 'agent',
            label: <Link to='/agent'>Agent</Link>,
        },
        {
            key: 'entities',
            label: <Link to='/entities'>Records</Link>,
        },
        {
            key: 'analytics',
            label: <Link to='/analytics'>Analytics</Link>,
        }
    ];

    return (
        <FlexBox
            stretch
            noGrow
            style={{
                paddingBlock: 10,
                paddingInline: x,
                position: 'relative',
            }}
        >
            <FlexBox
                justifyStart
                noGrow
                gap={x}
            >
                <Link 
                    to="/" 
                    style={{ 
                    display: 'flex', alignItems: 'center' }}
                >
                    <img
                        src={darkMode ? '/art/Syncretic-icon-negative.svg' : '/art/Syncretic-icon.svg'}
                        alt='Syncretic'
                        style={{
                            height: 36,
                        }}
                    />
                </Link>
                {sm ? (
                    <Menu
                        mode="horizontal"
                        style={{
                            minWidth: "100%",
                            background: "none",
                            border: "none",
                        }}
                        selectedKeys={[selectedKey]}
                        items={menuItems}
                    />
                ) : (
                    <Dropdown 
                        menu={{ 
                            items: menuItems,
                            style: {
                                width: '100%'
                            },
                        }} 
                        trigger={['click']}
                    >
                        <Button type="text" icon={<MenuOutlined />} />
                    </Dropdown>
                )}
            </FlexBox>
            <FlexBox
                justifyEnd
                gap={10}
            >
                <Search />
                <Link to="/notifications">
                    <Badge count={unreadNotifications?.length ?? 0}>
                        <Button
                            type={selectedKey === 'notifications' ? 'primary' : 'text'}
                            icon={<Icon.Bell/>}
                        />
                    </Badge>
                </Link>
                <Link to="/settings">
                    <Button
                        type={selectedKey === 'settings' ? 'primary' : 'text'}
                        icon={<Icon.Settings/>}
                    />
                </Link>
            </FlexBox>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: x,
                    right: x,
                    height: '1px',
                    backgroundColor: token.colorBorder,
                }}
            />
        </FlexBox>
    );
}